import React, { useEffect, useRef } from 'react'
import TextDecoration from '../utility/TextDecoration';
import VizSensor from 'react-visibility-sensor';
import styles from './martcard.module.css'

export const BasicHomeMart = (props) => {
    const listing = props.listing;
    const thumb = listing.thumbnailImage ? listing.thumbnailImage : 'assets/Desert.jpg'

    let isViewCounted = false;
    const timeOut = useRef(null);
    // const runTimer = (infocus) => {
    //     if (!isViewCounted && infocus)
    //         timeOut.current = setTimeout(() => countView(), 5000);
    //     else if (!infocus && timeOut.current)
    //         clearTimeout(timeOut.current)
    // }
    const handleClick = () => {
        !isViewCounted && countView();
        props.showContent(listing)
    }
    const countView = () => {
        timeOut.current && clearTimeout(timeOut.current)
        isViewCounted = true;
        //listingService.updateSingleListingViews(null, listing);
    }
    useEffect(() => {
        return () => timeOut.current && clearTimeout(timeOut.current)
    }, [timeOut])

    return (
        <VizSensor
            // onChange={(isVisible) => {
            //     runTimer(isVisible)
            // }}
        >
            <div key={listing.hpId} className={`col-lg-6 col-xl-6 ${styles.basic_listing_info}`}>
                <div className={`${styles.listing_info_box_basic_home} row form-row curser_hand mx-0`} onClick={handleClick}>
                    <div className="col-4 no_padding">
                        <div className={`${styles.home_thumb_image_basic}`}>
                            <img alt={listing.martName} className={`img-fluid ${styles.home_thumb_image_basic_img}`} loading='lazy' src={process.env.REACT_APP_IMAGES_URL + thumb} />
                        </div>
                    </div>
                    <div className={`col-8 ${styles.home_Desc}`}>
                        <h3 className={`${styles.home_link}`} >{TextDecoration.ellipsize(listing.martName, 100)}</h3>
                        
                        <div className={`${styles.basic_home_listing_info_box}`}>
                            {TextDecoration.ellipsize(listing.businessDescription, 150)}
                        </div>
                        <div className={`${styles.basic_home_listing_info_box_desc}`}>
                            <span className={styles.name_span}>Location:</span> {listing.businessAddress}
                        </div>
                    </div>
                    {(props.viewedContentIds.includes(listing.hpId)) ?
                        <div className="col-md-12">
                            <i className={`fas fa-check-circle ${styles.viewed_mark_home} ${styles.viewed_right_home}`}></i>
                        </div> : null
                    }
                </div>
            </div>
        </VizSensor>
    )
}