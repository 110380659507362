import React, { useState, useEffect } from "react";
import { HomeTab } from "./HomeTab";
import styles from './toggle.module.css'
import { useHistory } from "react-router-dom";


export const HomeTabs = (props) => {
    const [selectedTab, setSelectedTab] = useState("article");
    const [isToggled, setIsToggled] = useState(false);
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const fetchTabData = async (tabId) => {
        setLoading(true);
        console.log(`Fetching data for ${tabId}`);
        await new Promise((resolve) => setTimeout(resolve, 500));
        setLoading(false);
    };

    useEffect(() => {
        if (window.location.pathname === "/mart") {
            setIsToggled(true);
            setSelectedTab("mart");
            fetchTabData("mart"); // Fetch data when mounted on /mart
            props.onToggle("mart");
        } else {
            setIsToggled(false);
            setSelectedTab("article");
            fetchTabData("article"); // Fetch default data
            props.onToggle("article");
        }
    }, [window.location.pathname]);

    // API call when clicking toggle button
    // const handleToggle = async (id) => {
    //     const newState = !isToggled;
    //     console.log(`Making API call for toggle: ${newState}`);
    //     await new Promise((resolve) => setTimeout(resolve, 500));

    //     setIsToggled(newState);

    //     if (!newState) {
    //         history.replace(`/`);
    //         setSelectedTab("article");
    //         await fetchTabData("article");
    //         props.onToggle("article");

    //     } else {
    //         history.replace(`/mart`);
    //         setSelectedTab(id);
    //         await fetchTabData(id);
    //         props.onToggle("mart");
    //     }
    // };

    const handleToggle = async () => {
        const newState = !isToggled;
        setIsToggled(newState);

        const tabId = newState ? "mart" : "article";

        await fetchTabData(tabId); // Ensure data is loaded before state update

        setSelectedTab(tabId);
        props.onToggle(tabId);

        history.replace(newState ? "/mart" : "/");
    };

    const handleTabClick = async (id) => {
        setSelectedTab(id);
        await fetchTabData(id);
        props.onToggle(id);
    };

    return (
        <>
            <div className="billBoard">
                <ul style={{ background: "#e5f1e4", display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }} className="nav listing_type_tabs" role="tablist">
                    {isToggled ? (
                        <li className="nav-item" style={{ flex: 1, textAlign: "center", paddingTop: '50px' }}>
                            <h2 style={{ margin: 0, color: "#138808", fontWeight: 'bold' }}>WELCOME TO THE MART <i style={{ fontSize: '12px' }}>(Beta Version)</i></h2>
                        </li>
                    ) : (
                        <>
                            <HomeTab onClick={handleTabClick} title={"video_library"} id="article" active={selectedTab === "article"} />
                            <HomeTab onClick={handleTabClick} title={"photo_library"} id="photos" active={selectedTab === "photos"} />
                            <HomeTab onClick={handleTabClick} title={"auto_stories"} id="news" active={selectedTab === "news"} />
                            <HomeTab onClick={handleTabClick} title={"hotel_class"} id="reviews" active={selectedTab === "reviews"} />
                            <HomeTab onClick={handleTabClick} title={"storefront"} id="business" active={selectedTab === "business"} />
                            <HomeTab onClick={handleTabClick} title={"celebration"} id="event" active={selectedTab === "event"} />
                            <HomeTab onClick={handleTabClick} title={"work"} id="job" active={selectedTab === "job"} />
                        </>
                    )}
                    {isToggled ?
                        <li className="nav-item" style={{ marginLeft: "auto", color: '#000080', fontSize: '16px', paddingTop: '50px' }}>Mart Mode
                            <label className={styles.switch}>
                                <input type="checkbox" id="mart" active={selectedTab === "mart"} checked={isToggled} onChange={handleToggle} />
                                <span className={`${styles.slider} round`}></span>
                            </label>
                        </li> :
                        <li className="nav-item" style={{ marginLeft: "auto", color: '#000080', fontSize: '16px' }}>Mart Mode
                            <label className={styles.switch}>
                                <input type="checkbox" id="mart" active={selectedTab === "mart"} checked={isToggled} onChange={handleToggle} />
                                <span className={`${styles.slider} round`}></span>
                            </label>
                        </li>
                    }
                </ul>
            </div>
            <div className='large_rect'>
                <ul style={{ background: '#e5f1e4' }} className="nav listing_type_tabs_mobile" role="tablist">
                    {isToggled ? (
                        <li className="nav-item" style={{ flex: 1, textAlign: "center" }}>
                            <h2 style={{ margin: 0, paddingTop: '2px', color: "#138808", fontWeight: 'bold', fontSize: '12px' }}>WELCOME TO THE MART <br></br><i style={{ fontSize: '12px' }}>(Beta Version)</i></h2>
                        </li>
                    ) : (
                        <>
                            <HomeTab onClick={handleTabClick} title={'video_library'} id="article" active={selectedTab === "article"} />
                            <HomeTab onClick={handleTabClick} title={'photo_library'} id="photos" active={selectedTab === "photos"} />
                            <HomeTab onClick={handleTabClick} title={'auto_stories'} id="news" active={selectedTab === "news"} />
                            <HomeTab onClick={handleTabClick} title={'hotel_class'} id="reviews" active={selectedTab === "reviews"} />
                            <HomeTab onClick={handleTabClick} title={'storefront'} id={"business"} active={selectedTab === "business"} />
                            <HomeTab onClick={handleTabClick} title={'celebration'} id={"event"} active={selectedTab === "event"} />
                            <HomeTab onClick={handleTabClick} title={'work'} id={"job"} active={selectedTab === "job"} />
                        </>
                    )}
                    <li className="nav-item" style={{ marginLeft: "auto", color: '#000080', fontSize: '14px' }}>Mart Mode
                        <label className={styles.switch}>
                            <input type="checkbox" id="mart" active={selectedTab === "mart"} checked={isToggled} onChange={handleToggle} />
                            <span className={`${styles.slider} round`}></span>
                        </label>
                    </li>
                </ul>
            </div>
        </>
    );
};
