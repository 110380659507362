import React, { Component } from 'react';
import { connect } from 'react-redux'
import axios from 'axios';
import Loader from '../utility/Loader';
import LookUpMartContent from './LookUpMartContent';
import listingService from '../listings/service/listingService';

class LookUpMart extends Component {

    searchRequest = {}
    state = {
        autoScroll: false,
        listing_type: 'mart',
        featured: [],
        totalBasic: [],
        isListingLoading: true,
    }

    compareArrays = (array1, array2) => {
        if (!array1 || !array2)
            return false
        if (array1.length !== array2.length || array1.length === array2.length)
            return true
        array1.sort();
        array2.sort();
        for (var i = 0; i < array1.length; ++i)
            if (array1[i] !== array2[i]) return true;
        return false;
    }

    componentDidMount() {
        this.setState({ isListingLoading: true })

        if (this.props.location.state) {
            const searchRequest = this.props.location.state.searchRequest
            searchRequest.isSearchDataChanged = true;
            this.props.searchingData(searchRequest)
            // this.searchRequest = searchRequest
            //this.findListing(searchRequest)
            axios.post(process.env.REACT_APP_BACKEND_URL + `/abmgr/mart/search`, searchRequest).then(response => {
                const e = response.data;
                this.setState({ totalBasic: e })
            }).catch(err => {
                this.setState({ isListingLoading: false })
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.state !== prevProps.location.state) {
            const searchRequest = prevProps.location.state ? prevProps.location.state.searchRequest : []
            const currentSearchData = this.props.location.state ? this.props.location.state.searchRequest : []
            const isSearchDataChanged = searchRequest.distance !== currentSearchData.distance ||
                this.compareArrays(searchRequest.category, currentSearchData.category) ||
                this.compareArrays(searchRequest.subCategory, currentSearchData.subCategory) ||
                this.compareArrays(searchRequest.state, currentSearchData.state) ||
                this.compareArrays(searchRequest.city, currentSearchData.city) ||
                this.compareArrays(searchRequest.locality, currentSearchData.locality) ||
                this.compareArrays(searchRequest.neighbourhood, currentSearchData.neighbourhood) ||
                this.compareArrays(searchRequest.location, currentSearchData.location) ||
                this.compareArrays(searchRequest.keyword, currentSearchData.keyword)

            currentSearchData.isSearchDataChanged = isSearchDataChanged
            if (isSearchDataChanged) {
                this.setState({ isListingLoading: true }, () => {
                    axios.post(process.env.REACT_APP_BACKEND_URL + `/abmgr/mart/search`, currentSearchData)
                        .then(response => {
                            this.setState({ totalBasic: response.data, isListingLoading: false });
                        })
                        .catch(() => {
                            this.setState({ isListingLoading: false });
                        });
                });
            }
        }
    }

    sortByDate = (list) => {
        list.sort(function (a, b) {
            const date1 = new Date(b.createdDate);
            const date2 = new Date(a.createdDate);
            return date1 > date2 ? 1 : date1 < date2 ? -1 : 0;
        });
    }

    render() {

        const { listing_type, autoScroll } = this.state;
        const { featured, totalBasic } = this.state

        return (
            <div className='pt-5'>
                {(this.state.totalBasic.length) ?
                    <div role="tabpanel">
                        <LookUpMartContent
                            {...this.props}
                            places={featured}
                            totalBasicListings={this.state.totalBasic}
                            autoScroll={autoScroll} />
                    </div>
                    : <h2 className='no_data'>No content matching your search.</h2>}
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        searchingData: (data) => dispatch({ type: "Seraching_Data", search_data: data }),
        searchCounts: (data) => dispatch({ type: "Seraching_Counts", searching_counts: data }),
    }
}

const mapStateToProps = state => {
    return {
        listing_type: state.listing_type,
        searching_counts: state.searching_counts,
        isLoading: state.isLoading,
        lookUpPageState: state.lookUpPageState,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LookUpMart);