import axios from 'axios';
import { notifyUser, type } from '../utility/notifyUser';

const locationUrl = process.env.REACT_APP_BACKEND_URL + '/abmgr/location';

class locationService {

    async getStates() {
        return axios.get(locationUrl + '/state-dropdown')
            .then(response => {
                return response.data
            })
    }

    async addNewState(newState) {
        return axios.post(locationUrl + `/add-new-state`, newState)
            .then(response => {
                return response.data;
            }).catch(err => {
                if (err.response)
                    notifyUser('',err.response.data, type.DANGER);
                else
                    notifyUser('', "Opps...! Somthing went wrong", type.WARNING);
            })
    }

    async getCities(stateId) {
        return axios.get(locationUrl + `/city-dropdown/${stateId}`)
            .then(response => {
                return response.data;
            })
    }

    async addNewCity(newCity) {
        return axios.post(locationUrl + `/add-new-city`, newCity)
            .then(response => {
                return response.data;
            }).catch(err => {
                if (err.response)
                    notifyUser('', err.response.data, type.DANGER);
                else
                    notifyUser('', "Opps...! Somthing went wrong", type.WARNING);
            })
    }

    async getLocalities(cityId) {
        return axios.get(locationUrl + `/locality-dropdown/${cityId}`)
            .then(response => {
                return response.data;
            })
    }

    async addNewLocality(newLocality) {
        return axios.post(locationUrl + `/add-new-locality`, newLocality)
            .then(response => {
                return response.data;
            }).catch(err => {
                if (err.response)
                    notifyUser('',err.response.data, type.DANGER);
                else
                    notifyUser('', "Opps...! Somthing went wrong", type.WARNING);
            })
    }

    async getNeighbourhoods(localityId) {
        return axios.get(locationUrl + `/neighbourhood-dropdown/${localityId}`)
            .then(response => {
                return response.data;
            })
    }

    async addNewNeighbourhood(newNeighbourhood) {
        return axios.post(locationUrl + `/add-new-neighbourhood`, newNeighbourhood)
            .then(response => {
                return response.data;
            }).catch(err => {
                if (err.response)
                    notifyUser('', err.response.data, type.DANGER);
                else
                    notifyUser('', "Opps...! Somthing went wrong", type.WARNING);
            })
    }

    async getLocalitySiblings(localityId) {
        return axios.get(locationUrl + `/get-locality-siblings/${localityId}`)
            .then(response => {
                return response.data;
            })
    }

    async getCitySiblings(cityId) {
        return axios.get(locationUrl + `/get-city-siblings/${cityId}`)
            .then(response => {
                return response.data;
            })
    }

    async getAllLocations() {
        return axios.get(process.env.REACT_APP_BACKEND_URL + '/hpmgr/listing/getallstates')
            .then(response => {
                return response.data;
            })
    }
}

export default new locationService();