import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import authservice from '../Authentication/service/authservice';
import LocationService from '../services/LocationService';
import { notifyUser, type } from '../utility/notifyUser';
import { ButtonStyle } from '../UIElements/Buttons/ButtonStyle';
import { searchFilterNames } from '../RegxConst'
import styles from './filters.module.css'
import CategoryService from '../services/CategoryService';

export const NewFilterForm = (props) => {

    const [name, setName] = useState('');
    const [parent, setParent] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (parent && parent.value) {
            if (props.parentOptions.length == 0)
                setParent('')
            else {
                const index = props.parentOptions.findIndex(e => {
                    return e.value === parent.value
                })
                if (index === -1)
                    setParent('')
            }
        }
        if (props.id === 'SubCategory' && !props.showCategory) {
            setParent(props.parentOptions[0])
        }
    }, [props.parentOptions])

    const saveFilter = (e) => {
        e.preventDefault();
        if (!parent && props.id !== 'State' && props.id !== 'Category') {
            notifyUser(`No ${props.parentFilter} Selected`, `Please Select ${props.parentFilter}`, type.WARNING);
            return;
        }
        const data = {
            name: name,
            parentLocationId: parent ? parent.value : null,
            addedByUserId: authservice.getCurrentUserid(),
        }
        try {
            switch (props.id) {
                case 'State':
                    LocationService.addNewState(data).then(data => {
                        if (data) {
                            props.handleNewFilter(data)
                            notifyUser("", data.state + " has been added", type.INFO);
                            props.handleCancel()
                        }
                    })
                    break;
                case 'City':
                    LocationService.addNewCity(data).then(data => {
                        if (data) {
                            props.handleNewFilter(data)
                            notifyUser("", data.city + " has been added", type.INFO);
                            props.handleCancel()
                        }
                    })
                    break;
                case 'Locality':
                    LocationService.addNewLocality(data).then(data => {
                        if (data) {
                            props.handleNewFilter(data)
                            notifyUser("", data.locality + " has been added", type.INFO);
                            props.handleCancel()
                        }
                    })
                    break;
                case 'Neighbourhood':
                    LocationService.addNewNeighbourhood(data).then(data => {
                        if (data) {
                            props.handleNewFilter(data)
                            notifyUser("", data.neighbourhood + " has been added", type.INFO);
                            props.handleCancel()
                        }
                    })
                    break;

                case 'Category':
                    CategoryService.addNewCategory(data).then(data => {
                        if (data) {
                            props.handleNewFilter(data)
                            notifyUser("", data.category + " has been added", type.INFO);
                            props.handleCancel()
                        }
                    })
                    break;
                case 'SubCategory':
                    CategoryService.addNewSubCategory(data).then(data => {
                        if (data) {
                            props.handleNewFilter(data)
                            notifyUser("", data.subCategory + " has been added", type.INFO);
                            props.handleCancel()
                        }
                    })
                    break;
                default:
                    break;
            }
        } catch (err) {
            if (err.response)
                notifyUser("New " + props.id, "Error : " + err.response.data, type.WARNING);
            else
                notifyUser("New " + props.id, "Opps...! Somthing went wrong", type.WARNING);
        }
    }

    const handleParentChange = (e) => {
        setParent(e)
    }

    const handleName = (e) => {
        let title = props.id
        if (props.heading) {
            title = props.heading.includes('Videos') ? 'My video' : props.heading.includes('Pages') ? 'My Pages' : props.heading.includes('Reviews') ? 'My Reviews' : props.heading.includes('Photos') ? 'My Photos' : props.heading.includes('Album') ? 'My Album' : props.id
        }
        const name = e.target.value;
        setName(name);
        const trimmedValue = name.trim();
        if (trimmedValue.length < 3)
            setError(`${title} Name Too Short`)
        else if (trimmedValue.length > 50)
            setError(`${title} Name Too Long`)
        else
            setError(searchFilterNames.test(trimmedValue)
                ? ''
                : `${title} Name Contains Invalid Character`)
    }

    const parentOption = (props.id === 'SubCategory' && !props.showCategory) ? false : true

    return (
        <form id={'add' + props.id} onSubmit={saveFilter}>
            <div className={`form-row ${styles.add_new} row`}>
                <div className="form-group col-md-2">
                </div>
                <div className="form-group col-md-3">
                    {(props.parentOptions && parentOption) &&
                        <Select
                            placeholder={`Select ${props.parentFilter}`}
                            value={parent}
                            options={props.parentOptions}
                            onChange={handleParentChange}
                        />}
                </div>
                <div className="form-group col-md-5">
                    <input type="text" className="form-control" placeholder={`Add New ${props.id === 'SubCategory' && !props.showCategory ? 'Name' : props.id}`}
                        value={name} name={props.id} required autoFocus
                        onChange={handleName} />
                    <span className="error">{error}</span>
                </div>
                <div className="form-group col-md-1">
                    <ButtonStyle type="submit" className="btn" disabled={error.length}>Save</ButtonStyle>
                </div>
                <div className="form-group col-md-1">
                    <button type="button" className="btn btn-danger contact_subbtn"
                        onClick={props.handleCancel}>Cancel</button>
                </div>
            </div>
        </form>
    )
}