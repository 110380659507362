import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from './productpage.module.css'
import authservice from '../Authentication/service/authservice';
import { ButtonStyle } from "../UIElements/Buttons/ButtonStyle";
import { notifyUser, type } from '../utility/notifyUser';
import LargeRectangle from "../advertisements/LargeRectangle";
import BillBoard from "../advertisements/BillBoard";
import sty from '../listings/forms/addpost.module.css';

const CartPage = (props) => {
  const [cartItems, setCartItems] = useState([]);
  const userId = authservice.getCurrentUserid();
  const [totalPrice, setTotalPrice] = useState([]);
  const [platformFee, setPlatformFee] = useState([]);
  const [totalPayable, setTotalPayable] = useState([]);
  const [paymentGateway, setPaymentGateway] = useState([])
  const [errors, setErrors] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("option2");
  const [f, setF] = useState(true);
  const [paymentPhonePay, setPaymentPhonePay] = useState(false);
  const [radioError, setRadioError] = useState("");
  const [wallet, setWallet] = useState([]);

  const [formData, setFormData] = useState({
    name: '',
    address: '',
    address2: '',
    pin: null,
    phone: '',
    email: '',
    gstNumber: '',
    billingState: '',
    selectedOption: ''
  });

  const fetchCart = async () => {
    const userId = authservice.getCurrentUserid();

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/product/shopping-bag/${userId}?paymentMode=PHONEPE`
      );

      if (response.data && response.data.items) {
        const cartData = response.data.items.map((item) => ({
          productId: item.productId,
          name: item.productName,
          size: item.size,
          quantity: item.quantity,
          price: item.price,
          remainingStock: item.remainingStock,
          productImage: item.productImage
        }));

        setCartItems(cartData);
        setTotalPrice(response.data.totalPrice);
        setPlatformFee(response.data.platformFee);
        setTotalPayable(response.data.totalPayable);
        setPaymentGateway(response.data.paymentGateway);
        setWallet(response.data.walletBalance)
        updateTotalPrice()
      } else {
        setCartItems([]);
      }
    } catch (error) {
      console.error("❌ Error fetching cart data:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCart();
  }, []);

  const increaseQuantity = async (item) => {
    const userId = authservice.getCurrentUserid();

    if (item.quantity >= item.remainingStock) {
      alert(`Cannot add more. Only ${item.remainingStock} left in stock!`);
      return;
    }

    setCartItems((prevCart) => {
      const updatedCart = prevCart.map((cartItem) =>
        cartItem.productId === item.productId && cartItem.size === item.size
          ? { ...cartItem, quantity: cartItem.quantity + 1 }
          : cartItem
      );

      localStorage.setItem("cart", JSON.stringify(updatedCart));
      updateTotalPrice(updatedCart);

      return updatedCart;
    });
    const paymentMode = paymentPhonePay ? "PHONEPE" : f ? "WALLET" : null
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/product/shopping-bag/${userId}/products`,
        null,
        {
          params: {
            productId: item.productId,
            size: item.size, // Ensure correct size is sent
            quantity: item.quantity + 1,
            paymentMode: paymentMode
          },
          headers: { "Content-Type": "application/json" },
        }
      );
      setPaymentGateway(paymentGateway)
      setTotalPayable(totalPayable)
      //setWalletBalance(walletBalance)
    } catch (error) {
      console.error("Error increasing quantity:", error);
    }
  };

  const decreaseQuantity = async (item) => {
    if (item.quantity <= 1) return; // Prevent quantity from going below 1

    const userId = authservice.getCurrentUserid();

    setCartItems((prevCart) => {
      const updatedCart = prevCart.map((cartItem) =>
        cartItem.productId === item.productId && cartItem.size === item.size
          ? { ...cartItem, quantity: cartItem.quantity - 1 }
          : cartItem
      );

      localStorage.setItem("cart", JSON.stringify(updatedCart)); // Sync with localStorage
      updateTotalPrice(updatedCart);

      return updatedCart;
    });
    const paymentMode = paymentPhonePay ? "PHONEPE" : "WALLET"
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/product/shopping-bag/${userId}/products`,
        null,
        {
          params: {
            productId: item.productId,
            size: item.size, // Ensure correct size is sent
            quantity: item.quantity - 1,
            paymentMode: paymentMode
          },
          headers: { "Content-Type": "application/json" },
        }
      );
      setPaymentGateway(paymentGateway)
      setTotalPayable(totalPayable)
    } catch (error) {
      console.error("❌ Error decreasing quantity:", error);
    }
  };


  const updateTotalPrice = (updatedCart) => {
    const newTotalPrice = updatedCart.reduce((total, item) => total + item.quantity * item.price, 0);
    const newpaymentGateway = newTotalPrice * 0.02 // Assuming platform fee is 2%
    const newTotalPayable = newTotalPrice + newpaymentGateway + platformFee;

    setTotalPrice(newTotalPrice);
    setPaymentGateway(newpaymentGateway);
    setTotalPayable(newTotalPayable);
  };

  const removeItem = async (item) => {
    const userId = authservice.getCurrentUserid();

    setCartItems(cartItems.filter(item => !(item.productId && item.size)));
    const paymentMode = paymentPhonePay ? "PHONEPE" : "WALLET"
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/product/shopping-bag/${userId}/products/${item.productId}?size=${item.size}&paymentMode=${paymentMode}`,
      );
      fetchCart()
      setPaymentGateway(paymentGateway)
      setTotalPayable(totalPayable)
    } catch (error) {
      console.error("Error removing item:", error);
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: '' })); // Clear error when typing
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.address) newErrors.address = "Address is required.";
    if (!formData.pin) newErrors.pin = "Pin is required.";
    if (!formData.city) newErrors.city = "City is required.";
    if (!formData.state) newErrors.state = "State is required.";
    if (!formData.phone) newErrors.phone = "Phone Number is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };


  const handlePlaceOrder = async () => {
    if (!validateForm()) {
      return;
    }

    const userId = authservice.getCurrentUserid();
    const body = {
      state: formData.state,
      city: formData.city,
      pinCode: parseInt(formData.pin, 10),
      gstNumber: formData.gstNumber,
      deliveryAddress: `${formData.address}, ${formData.address2}`,
      fullName: formData.name,
      mobileNumber: formData.phone,
      platformFee: parseFloat(platformFee),
      paymentGateway: parseFloat(paymentGateway), // Update this if needed
      paymentMode: selectedOption === 'option1' ? "WALLET" : "PHONEPE"
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/product/place-order/${userId}`,
        body,
      );
      if (response.status === 200) {
        const paymentPhonepay = response.data
        if (paymentPhonepay && paymentPhonepay.success && paymentPhonepay.data.instrumentResponse.type === "PAY_PAGE") {
          const paymentUrl = paymentPhonepay.data.instrumentResponse.redirectInfo.url;
          console.log(paymentPhonepay.data.instrumentResponse.redirectInfo.url)
          window.location.href = paymentUrl;
        }
        setCartItems([]); // Clear the cart after successful order
        notifyUser("Success", "Order Placed Successfully", "success");
        setTimeout(() => {
          window.location.href = "/orders";
        }, 3000);
      } else {
        notifyUser("Error", "Failed to order place. Please try again!", "warning");
      }
    } catch (error) {
      notifyUser("Error", "An error occurred while placing the order", "danger");
    }
  };


  const handleRadioChange = (event) => {
    const value = event.target.value;
    const pattern = /^[0-9\b]+$/;
    if (pattern.test(value) || value === "") {
      setInputValue(value);
    }
  };

  useEffect(() => {

    if (selectedOption === 'option2') {
      fetchCart()
    } else {
      axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/product/shopping-bag/${userId}?paymentMode=WALLET`
      ).then(res => {
        const amount = res.data.paymentGateway
        const total = res.data.totalPayable
        setPaymentGateway(amount)
        setTotalPayable(total)
      })
    }
  }, [selectedOption]);

  const handleOptionChange = (event) => {
    const value = event.target ? event.target.value : "";
    setSelectedOption(value);
    setF(value === "option1");
    setPaymentPhonePay(value === "option2");

    setRadioError(value ? "" : "Please Select one payment method.");
  };

  return (
    <div className="admin-view-head">
      <div className="mt-4 g-2 col-md-12">
        <div className="col-md-2 add_button animated_back_button text-right">
          <ButtonStyle type="button" className="form-control btn btn-block btn_font"
            handleClick={() => props.history.goBack()}>&nbsp; &laquo; &nbsp; Go Back </ButtonStyle>
        </div>
        <div className={styles.cartContainer}>
          <h2 className={styles.heading}>🛒 Shopping Cart</h2>
          {cartItems.length === 0 ? (
            <p className={styles.emptyCart}>Your cart is empty.</p>
          ) : (
            <div>

              {/* {cartItems.map((item) => (
                <div key={item.productId} className={styles.cartItem}>
                  <div className={styles.detailsRow}>
                    <div className={styles.productImage}>
                      <img src={`${process.env.REACT_APP_IMAGES_URL}${item.productImage}`} alt={item.name} className={styles.cartimage} />
                    </div>
                    <div className={styles.details}>
                      <h3 className={styles.productName}>{item.name}</h3>
                      <p className={styles.size}><strong>Size: </strong>{item.size}</p>
                      <p className={styles.price}>Price: ₹{item.price * item.quantity}</p>
                    </div>

                    <div className={styles.quantity_container}>
                      <button className={styles.quantityBtn} onClick={() => decreaseQuantity(item)}> - </button>
                      <span className={styles.quantity}>{item.quantity}</span>
                      <button className={styles.quantityBtn} onClick={() => increaseQuantity(item)}> + </button>
                    </div> &nbsp;&nbsp;

                    <button className={styles.removeBtn} onClick={() => removeItem(item)}>Remove</button>
                  </div>
                </div>
              ))} */}

              {cartItems.map((item) => (
                <div key={item.productId} className={styles.cartItem}>
                  <div className={styles.detailsRow}>
                    <div className={styles.productImage}>
                      <img
                        src={`${process.env.REACT_APP_IMAGES_URL}${item.productImage}`}
                        alt={item.name}
                        className={styles.cartImage}
                      />
                    </div>
                    <div className={styles.details}>
                      <h3 className={styles.productName}>{item.name}</h3>
                      <p className={styles.size}><strong>Size: </strong>{item.size}<br></br>
                        <span className={styles.price}>Price: ₹{item.price * item.quantity}</span></p>
                    </div>
                    <div className={styles.quantityContainer}>
                      <button className={styles.quantityBtn} onClick={() => decreaseQuantity(item)}> - </button>
                      <span className={styles.quantity}>{item.quantity}</span>
                      <button className={styles.quantityBtn} onClick={() => increaseQuantity(item)}> + </button>
                    </div>
                    <button className={styles.removeBtn} onClick={() => removeItem(item)}>Remove</button>
                  </div>
                </div>
              ))}


              <div className="p-2">
                <h2 className={styles.productName}>Set Billing Address</h2>

                {[
                  { label: "Name *", name: "name" },
                  { label: "Address *", name: "address", placeholder: "House/Flat/Floor No" },
                  { label: " ", name: "address2", placeholder: "Area/Street/Locality" },
                  { label: "Pin *", name: "pin", placeholder: "Pin Code" },
                  { label: "City *", name: "city", placeholder: "City" },
                  { label: "State *", name: "state", placeholder: "State" },
                  { label: "Phone Number *", name: "phone", placeholder: "Phone Number" }
                ].map(({ label, name, placeholder }) => (
                  <div className="row form-row col-md-10" key={name}>
                    <div className="form-group col-md-3 label">
                      <label className={styles.bill}>{label}</label>
                    </div>
                    <div className="form-group col-md-8">
                      <input type="text" className="form-control" placeholder={placeholder} value={formData[name]} onChange={handleChange} name={name} />
                      {errors[name] && <span className="error" style={{ color: 'red' }}>{errors[name]}</span>}
                    </div>
                  </div>
                ))}

                <div className="row form-row col-md-10">
                  <div className="form-group col-md-3 label">
                    <label className={styles.bill}>GST Number</label>
                  </div>
                  <div className="form-group col-md-8">
                    <input type="text" className="form-control" value={formData.gstNumber} onChange={handleChange} name="gstNumber" />
                  </div>
                </div>
              </div>

              <div className="row form-row">
                <h2 className="set_heading">Choose Payment Mode</h2><br></br>
              </div>
              <div className="row form-row col-md-10">
                <div className="form-group col-md-2"></div>
                <p><strong>Wallet Amount: {wallet}</strong></p><br></br>
              </div>
              <div className="row form-row col-md-10">
                <div className="form-group col-md-2"></div>
                <label className='container_radio'> Wallet
                  <input type="radio" name="radio" value="option1" checked={selectedOption === "option1"} onChange={handleOptionChange} onInput={handleRadioChange} />
                  <span class="checkmark"></span>
                </label>
              </div>
              <div className="row form-row col-md-10">
                <div className="form-group col-md-2"></div>
                <label className='container_radio'> Payment Gateway (Debit/Credit Cards)
                  <input name="radio" type="radio" value="option2" checked={selectedOption === "option2"} onChange={handleOptionChange} onInput={handleRadioChange} />
                  <span class="checkmark"></span><br></br>
                  <p style={{ fontSize: '12px' }}><i>2% payment gateway charges applicable.</i></p>
                </label>
              </div>
              {radioError && <p style={{ color: "red" }}>{radioError}</p>}<br></br>

              <div className={styles.totalContainer}>
                <p className={styles.price}>Total Price: ₹{totalPrice}</p>
                <p className={styles.price}>Platform Fee: ₹{platformFee}</p>
                <p className={styles.price}>
                  Payment Gateway Charge: ₹{paymentGateway}
                </p>
                <h3 className={styles.total}>Total Payable: ₹{totalPayable}</h3>
                <button className={styles.checkoutBtn} onClick={handlePlaceOrder}>Place Order</button>
              </div>

            </div>
          )}
        </div>
      </div>
      <div className={`listings row ${sty.large_rect}`}>
        {!props.isAdmin && <LargeRectangle spot={"-1"} />}
      </div>
      <div className={`${sty.billBoard} row`} style={{ backgroundColor: '#e5e5f2', paddingTop: '100px', paddingBottom: '100px' }}>
        {!props.isAdmin && <BillBoard spot={"-1"} />}
      </div>
    </div>
  );
};

export default CartPage;
