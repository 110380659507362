import React, { Component } from 'react';
import { connect } from 'react-redux'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import $ from 'jquery';
import BillBoard from '../advertisements/BillBoard';
import LargeRectangle from '../advertisements/LargeRectangle';
import { TitleHeading } from '../UIElements/headings/TitleHeading';
import listingService from '../listings/service/listingService';
import { BasicMart } from './BasicMart';

class LookUpMartContent extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
        this.props.autoScroll && this.scrollPage(this.getFocusAdvrtElementId());
        console.log("Basic Listings:", this.props.totalBasicListings);
    }

    scrollPage = (elementId) => {
        if (!elementId)
            return;

        const header = document.getElementById('user_header');
        const adjustment = header.getBoundingClientRect().height + 25;
        let y = 0, element = null; // y (y coordinate where window will will be scrolled), element (advertisement which will be on top)

        $(document).ready(function () {
            element = document.getElementById(elementId);
            if (element)
                y = element.getBoundingClientRect().top - adjustment;
            if (!this.continueAutoScroll)
                y += window.scrollY
            window.scrollTo({ top: y, behavior: 'smooth' });
        })
    }

    // getFocusAdvrtElementId = () => {
    //     if (this.props.listing_type === 'article' || this.props.listing_type === 'clips' || this.props.listing_type === 'news' || this.props.listing_type === 'photos' || this.props.listing_type === 'album' || this.props.listing_type === 'reviews') {
    //         return "bill-board-3";
    //     }
    // }

    getListingDetails = (listing, section) => {

        const lookUpPageState = {
            listingType: this.props.listing_type,
            data: [...this.props.totalBasicListings]
        }
        this.props.setLookUpPageState(lookUpPageState);

        const searchCriteria = listingService.getListingSettings(listing, this.props.current_coords)
        const listingPath = encodeURI(`/mart/${listing.martId}/products`);
        this.props.history.push(listingPath, {
            place: section,
            featured: this.props.places,
            isViewed: this.props.viewedListings.includes(listing.hpId),
            searchCriteria: this.props.location.state.searchRequest,
            isSearching: true,
        });
    }

    render() {
        return (
            <div>

                {/* Total Basic  Listings  */}
                {console.log("Passing to MartCards:", this.props.totalBasicListings)}
                {this.props.totalBasicListings.length > 0 ?
                    <div >
                        <TitleHeading text={'All Basic Listings'} />
                        <div className="row">
                            <BasicMart listings={this.props.totalBasicListings}
                                viewedContentIds={this.props.viewedListings}
                                showContent={this.getListingDetails}
                                type={this.props.listing_type} />
                        </div>
                        {<div className="no_more">------------- No More Basic {this.props.listing_type} Listings to Display -------------</div>}
                        <div className='row'>
                            {this.props.isBillboard ? <LargeRectangle spot={"-9"} /> : <div className='col-md-12 ' style={{ backgroundColor: '#e5e5f2', paddingTop: '100px', paddingBottom: '100px' }}><BillBoard spot={"-9"} /></div>}
                        </div>
                    </div> : null}
                {/* end of Aotal Basic  Listings   */}

               
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLookUpPageState: (data) => dispatch({ type: "LOOKUP_PAGE_STATE", lookUpPageState: data }),
    }
}

const mapStateToProps = state => {
    return {
        current_coords: state.current_coords,
        listingFieldsData: state.listingFieldsData,
        viewedListings: state.viewedListings,
        lookUpPageState: state.lookUpPageState,
        isBillboard: state.isBillboard,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LookUpMartContent);