import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { validEmailRegex, mobilenumberRegex, ifscRegex, validateForm, pan, gstNumber } from '../RegxConst'
import { Form, Button, Table } from 'react-bootstrap';
import { TitleHeading } from '../UIElements/headings/TitleHeading';
import styles from '../listings/forms/addpost.module.css';
import style from '../UIElements/Form_label/FormLabels.module.css'
import sty from './martcard.module.css'
import BillBoard from '../advertisements/BillBoard';
import LargeRectangle from '../advertisements/LargeRectangle';
import { notifyUser, type } from '../utility/notifyUser';
import SetLocation from '../SetFilters/SetLocation/SetLocation';
import authservice from '../Authentication/service/authservice';
import axios from 'axios';
import SetCategory from '../SetFilters/SetCategory/SetCategory';
import { DatePicker, TimePicker } from 'antd';
import moment from 'moment';
import { faBullseye } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import SetMartCategory from '../SetFilters/SetCategory/SetMartCategory';

const ManageMart = (props) => {
    const [formVisible, setFormVisible] = useState(false);
    const [showApprovalPopup, setShowApprovalPopup] = useState(false);
    const [thumbnailImage, setThumbnailImage] = useState(null);
    const [panImage, setPanImage] = useState(null);
    const [isThumbnailPicValid, setIsThumbnailPicValid] = useState(false);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        id: null,
        userId: authservice.getCurrentUserid(),
        martName: '',
        businessDescription: '',
        thumbnailImage: null,
        thumbnailDescription: '',
        businessAddress: '',
        deletedAddresses: '',
        contactName: '',
        contactNumber: '',
        certifications: [],
        homeDeliveryAvailable: false,
        pickupAvailable: false,
        open: '',
        workingHours: [],
        categories: [],
        subCategories: [],
        country: [],
        states: [],
        cities: [],
        localities: [],
        neighbourhoods: [],
        bankName: '',
        accountHolderName: '',
        bankAccountNumber: '',
        ifsc: '',
        panNum: '',
        panImage: null,
        certificateImage: null,
        panLinkWithAadhar: false,
        gstNumber: '',
        lattitude: null,
        longitude: null,
    });
    const [marts, setMarts] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [selectedSubCategories, setSelectedSubCategories] = useState([]);
    const [categoryValidation, setCategoryValidation] = useState(null);
    const [subCategoryValidation, setSubCategoryValidation] = useState(null);
    const [allLocations, setAllLocations] = useState([]);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedLocality, setSelectedLocality] = useState(null);
    const [selectedNeighbourhood, setSelectedNeighbourhood] = useState(null);
    const [bankAccountNumber, setBankAccountNumber] = useState("");
    const bankAvailabilityTimer = useRef(null);
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    const fetchMarts = async () => {
        const userId = authservice.getCurrentUserid();
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/all/${userId}`);
            if (response.status === 200) {
                setMarts(response.data);
            } else {
                alert("Failed to fetch mart data.");
            }
        } catch (error) {
            console.error("Error fetching mart data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMarts();
    }, []);



    const [workingHours, setWorkingHours] = useState({
        monday_open: "", monday_close: "",
        tuesday_open: "", tuesday_close: "",
        wednesday_open: "", wednesday_close: "",
        thursday_open: "", thursday_close: "",
        friday_open: "", friday_close: "",
        saturday_open: "", saturday_close: "",
        sunday_open: "", sunday_close: ""
    });

    const handleTimeChange = (timeString, day, type) => {
        setWorkingHours((prev) => {
            let updatedState = { ...prev, [`${day}_${type}`]: timeString };

            // If Monday's time is changed, apply it to all other days
            if (day === 'monday') {
                ['tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].forEach(d => {
                    updatedState[`${d}_${type}`] = timeString;
                });
            }

            return updatedState;
        });

        // Validate closing time
        if (type === 'close') {
            setWorkingHours((prev) => {
                const fromTime = moment(prev[`${day}_open`], 'h:mm A');
                const toTime = moment(timeString, 'h:mm A');

                if (prev[`${day}_open`] && !toTime.isAfter(fromTime)) {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [day]: 'Closing time must be after opening time'
                    }));
                } else {
                    setErrors((prevErrors) => {
                        const newErrors = { ...prevErrors };
                        delete newErrors[day];
                        return newErrors;
                    });
                }

                return prev;
            });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            if (value.trim() !== "") {
                delete newErrors[name];
            }
            return newErrors;
        });
    };


    const handleRadioInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value === "true", // Convert string to boolean
        }));
    };

    const handleThumbnailChange = async (event) => {
        const file = event.target.files[0];

        if (file && file.type.startsWith("image")) {
            const reader = new FileReader();
            reader.onload = () => {
                setThumbnailImage(reader.result);
            };
            reader.readAsDataURL(file);

            const formData = new FormData();
            formData.append("photo", file);

            axios.post(`${process.env.REACT_APP_BACKEND_URL}/abmgr/user/upload-pan-card`, formData)
                .then(response => {
                    if (response.status === 200) {
                        const uploadedFileName = response.data;
                        setFormData(prev => ({
                            ...prev,
                            thumbnailImage: uploadedFileName,
                        }));
                        setIsThumbnailPicValid(true);
                    } else
                        setIsThumbnailPicValid(false);

                })
                .catch(error => {
                    setIsThumbnailPicValid(false);
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        thumbnailImage: "Please upload a square image with minimum 425px x 425px.",
                    }));
                });
        }
    };

    // Cancel uploaded thumbnail
    const cancelThumbnail = () => {
        setThumbnailImage(null);
        setFormData(prev => ({
            ...prev,
            thumbnailImage: null,
        }));
        document.getElementById("thumbnail-input").value = ""; // Clear file input
    };

    const handlePanChange = async (event) => {
        const file = event.target.files[0];

        if (file && file.type.startsWith("image")) {
            const reader = new FileReader();
            reader.onload = () => {
                setPanImage(reader.result);
            };
            reader.readAsDataURL(file);

            const formData = new FormData();
            formData.append("photo", file);
            const { name, value } = event.target;

            axios.post(`${process.env.REACT_APP_BACKEND_URL}/abmgr/user/upload-pan-card`, formData)
                .then(response => {
                    if (response.status === 200) {
                        setFormData(prev => ({
                            ...prev,
                            panImage: response.data,
                        }));
                        setErrors((prevErrors) => {
                            const newErrors = { ...prevErrors };
                            if (value.trim() !== "") {
                                delete newErrors[name]; // Remove error for this field
                            }
                            return newErrors;
                        });
                    }
                })
                .catch(error => {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        panImage: "Please upload a valid Pan Image.",
                    }));
                });
        }
    };

    // Cancel uploaded PAN Image
    const cancelPanImage = () => {
        setPanImage(null);
        setFormData(prev => ({
            ...prev,
            panImage: null,
        }));
        document.getElementById("pan-input").value = ""; // Clear file input
    };

    const handleCertificateChange = async (index, field, file) => {
        const formData = new FormData();
        formData.append("photo", file);

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/abmgr/user/upload-pan-card`, formData);

            if (response.status === 200) {
                handleCertifications(index, field, response.data);
            }
        } catch (error) {
            notifyUser("Error", "Failed to upload certificate image", "warning");
        }
    };

    // Cancel uploaded certificate
    const cancelCertificateImage = (index) => {
        setFormData((prev) => ({
            ...prev,
            certifications: prev.certifications.filter((_, i) => i !== index), // Remove the selected certification
        }));
    };

    const handleCertifications = (index, field, value) => {
        const updatedCertifications = [...formData.certifications];
        updatedCertifications[index] = {
            ...updatedCertifications[index],
            [field]: value,
        };
        setFormData({ ...formData, certifications: updatedCertifications });
    };


    const addCertificationField = () => {
        setFormData({
            ...formData,
            certifications: [...formData.certifications, { name: '', image: null }],
        });
    };

    const handleSubmit = async () => {

        const formattedData = Object.keys(workingHours).reduce((acc, key) => {
            const [day, type] = key.split('_');
            if (!acc[day]) acc[day] = { day };
            acc[day][type === 'open' ? 'openingTime' : 'closingTime'] = workingHours[key] || null;
            return acc;
        }, {});

        const finalData = Object.values(formattedData);

        const isFormValid = validateFormValues(formData);
        const thumbnailImage = formData.thumbnailImage
            ? formData.thumbnailImage.split("/").pop()
            : formData.thumbnailImage;
        const panImage = formData.panImage
            ? formData.panImage.split("/").pop()
            : formData.panImage;
        const certificateImage = formData.certificateImage
            ? formData.panImage.split("/").pop()
            : formData.certificateImage;
        if (isFormValid) {
            const martDTO = {
                id: null,
                userId: authservice.getCurrentUserid(),
                name: formData.martName,
                businessDescription: formData.businessDescription,
                thumbnailImage: thumbnailImage,
                thumbnailDescription: formData.thumbnailDescription,
                businessAddress: formData.businessAddress,
                contactName: formData.contactName,
                contactNumber: formData.contactNumber,
                // certifications: [],
                certifications: formData.certifications.map(cert => ({
                    name: cert.name,
                    image: cert.image,
                })),
                homeDeliveryAvailable: formData.homeDeliveryAvailable,
                pickupAvailable: formData.pickupAvailable,
                workingHours: finalData,
                categories: formData.categories,
                subCategories: formData.subCategories,
                country: 'India',
                states: formData.states,
                cities: formData.cities,
                localities: formData.localities,
                neighbourhoods: formData.neighbourhoods,
                bankName: formData.bankName,
                accountHolderName: formData.accountHolderName,
                bankAccountNumber: formData.bankAccountNumber,
                ifsc: formData.ifsc,
                pan: formData.panNum,
                panImage: panImage,
                panLinkWithAadhar: formData.panLinkWithAadhar,
                gstNumber: formData.gstNumber,
                lattitude: null,
                longitude: null

            };

            axios.post(`${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/save`, martDTO)
                .then((response) => {
                    if (response.status === 200) {
                        notifyUser("Success", "Mart added successfully", "success");
                        setMarts([...marts, { ...formData }]);
                        setFormVisible(false)
                        fetchMarts();
                        history.push(`/mart/${authservice.getCurrentUserName()}`)
                    } else {
                        notifyUser("Error", response.data, type.DANGER)
                    }
                })
                .catch((error) => {
                    console.error("Error adding mart:", error);
                    notifyUser("Error", "Error adding mart", "warning")
                });
        }
    }

    const handleAccount = (e) => {
        if (bankAvailabilityTimer.current) clearTimeout(bankAvailabilityTimer.current);

        const updatedBankAccountNumber = e.target.value.toUpperCase();
        setBankAccountNumber(updatedBankAccountNumber);

        bankAvailabilityTimer.current = setTimeout(async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/abmgr/auth/check-bank-account-number?bankAccountNumber=${updatedBankAccountNumber}`
                );
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    bankAccountNumber: response.data,
                }));
            } catch (error) {
                console.error("Error checking bank account number:", error);
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    bankAccountNumber: "Unable to verify account number",
                }));
            }
        }, 1000);
    };

    // Clean up the timer on component unmount
    useEffect(() => {
        return () => {
            if (bankAvailabilityTimer.current) clearTimeout(bankAvailabilityTimer.current);
        };
    }, []);


    const validateFormValues = (values) => {
        const errors = {};
        let isValid = true;

        const isEmpty = (value) => !value;
        const isValidPhone = (contactNumber) => mobilenumberRegex.test(contactNumber);
        const isValidIFSC = (ifsc) => ifscRegex.test(ifsc);
        const isValidPAN = (panNum) => pan.test(panNum);
        const isValidGST = (gst) => gstNumber.test(gst);

        // Required field validations
        if (isEmpty(values.martName)) {
            isValid = false;
            errors.martName = 'Mart name is required';
        }
        if (isEmpty(values.businessDescription)) {
            isValid = false;
            errors.businessDescription = 'Business description is required';
        }
        if (!thumbnailImage) {
            isValid = false;
            errors.thumbnailImage = 'Thumbnail Image is required';
        }
        if (isEmpty(values.thumbnailDescription)) {
            isValid = false;
            errors.thumbnailDescription = 'Thumbnail description is required';
        }
        if (isEmpty(values.businessAddress)) {
            isValid = false;
            errors.businessAddress = 'Business address is required';
        }
        if (isEmpty(values.contactName)) {
            isValid = false;
            errors.contactName = 'Contact name is required';
        }
        if (!isValidPhone(values.contactNumber)) {
            isValid = false;
            errors.contactNumber = 'Valid phone number is required';
        }
        if (isEmpty(values.subCategories)) {
            isValid = false;
            errors.subCategories = 'At least one subcategory is required';
        }
        if (isEmpty(values.bankName)) {
            isValid = false;
            errors.bankName = 'Bank name is required';
        }
        if (isEmpty(values.accountHolderName)) {
            isValid = false;
            errors.accountHolderName = 'Account holder name is required';
        }
        if (isEmpty(values.bankAccountNumber)) {
            isValid = false;
            errors.bankAccountNumber = 'Bank account number is required';
        }
        if (!isValidIFSC(values.ifsc)) {
            isValid = false;
            errors.ifsc = 'Valid IFSC code is required';
        }
        if (!isValidPAN(values.panNum)) {
            isValid = false;
            errors.panNum = 'Valid PAN is required';
        }
        if (!panImage) {
            isValid = false;
            errors.panImage = 'Valid PAN Image is required';
        }

        setErrors(errors);
        return isValid;
    };

    useEffect(() => {
        const fetchAllCategories = async () => {
            try {
                let userId = authservice.getCurrentUser().id;
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/abmgr/category/get-mart-subcategories/${userId}`
                );

                const categoriesData = response.data;
                console.log(categoriesData)
                setAllCategories(categoriesData);

                if (categoriesData.length > 0) {
                    setFormData((prev) => ({
                        ...prev,
                        categories: [categoriesData[0].categoryId],
                    }));
                }
                if (props.fullCategory) {
                    props.fullCategory(categoriesData);
                }
            } catch (error) {
                console.log(error, 'Getting data failed');
            }
        };

        fetchAllCategories();
    }, [props]);

    const showCategory = true;

    const handleNewCategory = (data) => {
        setAllCategories((prevCategories) => [...prevCategories, data]);
    };

    const handleCategoryChange = (selectedCategories) => {
        const categoriesArray = selectedCategories.map(category => category.value);
        setFormData(prev => ({
            ...prev,
            categories: categoriesArray
        }));
    };

    const handleSubCategoryChange = (selectedSubCategories) => {
        const subCategoriesArray = selectedSubCategories.map(subCategory => subCategory.value);
        setFormData(prev => ({
            ...prev,
            subCategories: subCategoriesArray
        }));
    };

    const handleSetCategoryValidationMethod = (m) => {
        setCategoryValidation(m);
    };

    const handleSetSubCategoryValidationMethod = (m) => {
        setSubCategoryValidation(m);
    };

    useEffect(() => {
        const fetchAllLocations = async () => {
            try {
                const [getFullAddress] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_BACKEND_URL}/hpmgr/listing/getallstates`),
                ]);
                setAllLocations(getFullAddress.data);
                if (props.fullAddress) {
                    props.fullAddress(getFullAddress.data);
                }
            } catch (error) {
                console.log(error, 'Getting data failed');
            }
        };

        fetchAllLocations();
    }, [props]);

    const handleNewLocation = (data) => {
        setAllLocations((prevLocations) => [...prevLocations, data]);
    };

    const handleStateChange = (selectedStates) => {
        const statesArray = selectedStates.map(state => state.value);
        setFormData(prev => ({
            ...prev,
            states: statesArray
        }));
    };

    const handleCityChange = (selectedCities) => {
        const citiesArray = selectedCities.map(city => city.value);
        setFormData(prev => ({
            ...prev,
            cities: citiesArray
        }));
    };

    const handleLocalityChange = (selectedLocalities) => {
        const localitiesArray = selectedLocalities.map(locality => locality.value);
        setFormData(prev => ({
            ...prev,
            localities: localitiesArray
        }));
    };

    const handleNeighbourhoodChange = (selectedNeighbourhoods) => {
        const neighbourhoodArray = selectedNeighbourhoods.map(neighbourhood => neighbourhood.value);
        setFormData(prev => ({
            ...prev,
            neighbourhoods: neighbourhoodArray
        }));
    };

    const handleEditMart = (martId) => {
        history.push(`/edit-mart/${martId}`);
    };

    useEffect(() => {
        if (window.location.pathname === `/mart/add/${authservice.getCurrentUserName()}`) {
            setFormVisible(true);
        } else {
            setFormVisible(false);
            history.push(`/mart/${authservice.getCurrentUserName()}`)
        }
    }, [window.location.pathname]);

    const handleAddMartClick = async () => {
        const userId = authservice.getCurrentUserid();
        try {
            const response = await axios.get(process.env.REACT_APP_BACKEND_URL + `/abmgr/user/get-content-approval-status/${userId}`);

            if (response.data.approvalFrMart === true) {
                setFormVisible(true);
                history.push(`/mart/add/${authservice.getCurrentUserName()}`);
            } else {
                setShowApprovalPopup(true);
            }
        } catch (error) {
            console.error("Error fetching mart subcategories:", error);
            setShowApprovalPopup(true);
        }
    };

    const toggleOpen = async (martId, newStatus) => {
        try {
            await axios.put(`${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/update/${martId}/status?open=${newStatus}`);

            setMarts((prevMarts) =>
                prevMarts.map((mart) =>
                    mart.id === martId ? { ...mart, open: newStatus } : mart
                )
            );
        } catch (error) {
            console.error("Error updating mart status:", error);
            alert("Failed to update status. Please try again.");
        }
    };



    return (
        <div className="admin-view-head">
            <div className="mt-4 g-2 col-md-12">
                <TitleHeading text='Mart Management' />
                <Button
                    variant="primary"
                    className="mb-3 secondary"
                    onClick={() => handleAddMartClick(null)}>
                    Add Mart
                </Button>
                {formVisible && (
                    <div>
                        {/* <div className="popup-content"> */}
                        <Button variant="danger" className="popup-close-btn" onClick={() => setFormVisible(false)}>
                            &times;
                        </Button>
                        <Form>
                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-md-right text-left`}><Form.Label>Mart Name*</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="text"
                                            name="martName"
                                            value={formData.martName}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {errors.martName && <p style={{ color: "red" }}>{errors.martName}</p>}
                                    </div>
                                </div>
                            </Form.Group>
                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-md-right text-left`}><Form.Label>Business Description*</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            as="textarea"
                                            name="businessDescription"
                                            value={formData.businessDescription}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {errors.businessDescription && <p style={{ color: "red" }}>{errors.businessDescription}</p>}
                                    </div>
                                </div>
                            </Form.Group>
                            <SetMartCategory
                                showCategory={showCategory}
                                setCategoryValidationMethod={handleSetCategoryValidationMethod}
                                setSubCategoryValidationMethod={handleSetSubCategoryValidationMethod}
                                //heading="Set Category"
                                // isMulti
                                allCategories={allCategories}
                                _handleNewCategory={handleNewCategory}
                                newFilterForm={true}
                                selectedCategories={formData.categories}
                                selectedSubCategories={[selectedSubCategories]}
                                _handleCategory={handleCategoryChange}
                                _handleSubCategory={handleSubCategoryChange}
                                wrapperClass="col-md-12"
                                labelClass="col-md-2"
                            />

                            <SetLocation
                                heading="Area Served"
                                isMulti
                                allLocations={allLocations}
                                _handleNewLocation={handleNewLocation}
                                newFilterForm={true}
                                //disabled={disable}
                                selectedStates={[selectedState]}
                                selectedCities={[selectedCity]}
                                selectedLocalities={[selectedLocality]}
                                selectedNeighbourhoods={[selectedNeighbourhood]}
                                _handleState={handleStateChange}
                                _handleCity={handleCityChange}
                                _handleLocality={handleLocalityChange}
                                _handleNeighbourhood={handleNeighbourhoodChange}
                                wrapperClass="col-md-12"
                                labelClass="col-md-2"
                            />

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-md-right text-left`}><Form.Label>Thumbnail Image*</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="file"
                                            name="thumbnailImage"
                                            onChange={handleThumbnailChange}
                                            accept="image/*"
                                            id="thumbnail-input"
                                        />
                                        {thumbnailImage && isThumbnailPicValid && (
                                            <div className={sty.image_container}>
                                                <img src={thumbnailImage} alt="Thumbnail" className={sty.preview_image} />
                                                <button className={sty.cancel_btn} onClick={cancelThumbnail}>&times;</button>
                                            </div>
                                        )}
                                        {!isThumbnailPicValid && <p style={{ color: "red" }}>{errors.thumbnailImage}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-md-right text-left`}><Form.Label>Thumbnail Description*</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            as="textarea"
                                            name="thumbnailDescription"
                                            value={formData.thumbnailDescription}
                                            onChange={handleInputChange}
                                        />
                                        {errors.thumbnailDescription && <p style={{ color: "red" }}>{errors.thumbnailDescription}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-md-right text-left`}><Form.Label>Business Address*</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type='text'
                                            name="businessAddress"
                                            value={formData.businessAddress}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {errors.businessAddress && <p style={{ color: "red" }}>{errors.businessAddress}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <div className='col-md-10 justify-content-center p-1 ml-4'>
                                <div className={`${style.addpost_label} col-md-2 text-md-right text-left`}><Form.Label>Working Hours*</Form.Label></div>
                                {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(day => (
                                    <div key={day} className="col-md-10">
                                        <label className="col-md-2 text-md-right text-left">{day.charAt(0).toUpperCase() + day.slice(1)}</label>
                                        <TimePicker use12Hours format="h:mm A" minuteStep={1}
                                            value={workingHours[`${day}_open`] ? moment(workingHours[`${day}_open`], 'h:mm A') : null}
                                            onChange={(time, timeString) => handleTimeChange(timeString, day, 'open')}
                                            placeholder="Select AM/PM"
                                            defaultOpenValue={moment('12:00 AM', 'h:mm A')}
                                        />&nbsp; &nbsp;
                                        <TimePicker use12Hours format="h:mm A" minuteStep={1}
                                            value={workingHours[`${day}_close`] ? moment(workingHours[`${day}_close`], 'h:mm A') : null}
                                            onChange={(time, timeString) => handleTimeChange(timeString, day, 'close')}
                                            placeholder="Select AM/PM"
                                            defaultOpenValue={moment('12:00 AM', 'h:mm A')}
                                        />
                                        {errors[day] && <p style={{ color: 'red' }}>{errors[day]}</p>}
                                    </div>
                                ))}
                            </div>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-md-right text-left`}><Form.Label>Contact Name*</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="text"
                                            name="contactName"
                                            value={formData.contactName}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {errors.contactName && <p style={{ color: "red" }}>{errors.contactName}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-md-right text-left`}><Form.Label>Contact Number*</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="tel"
                                            name="contactNumber"
                                            value={formData.contactNumber}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {errors.contactNumber && <p style={{ color: "red" }}>{errors.contactNumber}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-md-right text-left`}><Form.Label>Home Delivery Available</Form.Label></div>
                                    <div className='col-md-10'>
                                        <div className='row p-2'>
                                            <div className='pl-2'>
                                                <Form.Check
                                                    type="radio"
                                                    label="Yes"
                                                    name="homeDeliveryAvailable"
                                                    value={true}
                                                    checked={formData.homeDeliveryAvailable === true}
                                                    onChange={handleRadioInputChange}
                                                />
                                            </div>
                                            <div className='pl-2'>
                                                <Form.Check
                                                    type="radio"
                                                    label="No"
                                                    name="homeDeliveryAvailable"
                                                    value={false}
                                                    checked={formData.homeDeliveryAvailable === false}
                                                    onChange={handleRadioInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-md-right text-left`}><Form.Label>Pickup Available</Form.Label></div>
                                    <div className='col-md-10'>
                                        <div className='row p-2'>
                                            <div className='pl-2'>
                                                <Form.Check
                                                    type="radio"
                                                    label="Yes"
                                                    name="pickupAvailable"
                                                    value={true}
                                                    checked={formData.pickupAvailable === true}
                                                    onChange={handleRadioInputChange}
                                                />
                                            </div>
                                            <div className='pl-2'>
                                                <Form.Check
                                                    type="radio"
                                                    label="No"
                                                    name="pickupAvailable"
                                                    value={false}
                                                    checked={formData.pickupAvailable === false}
                                                    onChange={handleRadioInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-md-right text-left`}><Form.Label>Bank Name*</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="text"
                                            name="bankName"
                                            value={formData.bankName}
                                            // onChange={handleInputChange}
                                            onChange={(e) => handleInputChange({ target: { name: e.target.name, value: e.target.value.toUpperCase() } })}
                                            required
                                        />
                                        {errors.bankName && <p style={{ color: "red" }}>{errors.bankName}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-md-right text-left`}><Form.Label>Account Holder Name*</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="text"
                                            name="accountHolderName"
                                            value={formData.accountHolderName}
                                            //onChange={handleInputChange}
                                            onChange={(e) => handleInputChange({ target: { name: e.target.name, value: e.target.value.toUpperCase() } })}
                                            required
                                        />
                                        {errors.accountHolderName && <p style={{ color: "red" }}>{errors.accountHolderName}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-md-right text-left`}><Form.Label>Bank Account Number*</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="text"
                                            name="bankAccountNumber"
                                            value={formData.bankAccountNumber}
                                            onChange={handleAccount && handleInputChange}
                                            required
                                        />
                                        {errors.bankAccountNumber && <p style={{ color: "red" }}>{errors.bankAccountNumber}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-md-right text-left`}><Form.Label>IFSC Code*</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="text"
                                            name="ifsc"
                                            value={formData.ifsc}
                                            onChange={(e) => handleInputChange({ target: { name: e.target.name, value: e.target.value.toUpperCase() } })}
                                            required
                                        />
                                        {errors.ifsc && <p style={{ color: "red" }}>{errors.ifsc}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-md-right text-left`}><Form.Label>PAN*</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="text"
                                            name="panNum"
                                            value={formData.panNum}
                                            onChange={(e) => handleInputChange({ target: { name: e.target.name, value: e.target.value.toUpperCase() } })}
                                            //onChange={handleInputChange}
                                            required
                                        />
                                        {errors.panNum && <p style={{ color: "red" }}>{errors.panNum}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-md-right text-left`}><Form.Label>PAN Image*</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="file"
                                            name="panImage"
                                            onChange={handlePanChange}
                                            accept="image/*"
                                            required
                                            id="pan-input"
                                        />
                                        {panImage && (
                                            <div className={sty.image_container}>
                                                <img src={panImage} alt="PAN" className={sty.preview_image} />
                                                <button className={sty.cancel_btn} onClick={cancelPanImage}>&times;</button>
                                            </div>
                                        )}
                                        {errors.panImage && <p style={{ color: "red" }}>{errors.panImage}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-md-right text-left`}><Form.Label>Pan Link with Aadhar</Form.Label></div>
                                    <div className='col-md-10'>
                                        <div className='row p-2'>
                                            <div className='pl-2'>
                                                <Form.Check
                                                    type="radio"
                                                    label="Yes"
                                                    name="panLinkWithAadhar"
                                                    value={true}
                                                    checked={formData.panLinkWithAadhar === true}
                                                    onChange={handleRadioInputChange}
                                                />
                                            </div>
                                            <div className='pl-2'>
                                                <Form.Check
                                                    type="radio"
                                                    label="No"
                                                    name="panLinkWithAadhar"
                                                    value={false}
                                                    checked={formData.panLinkWithAadhar === false}
                                                    onChange={handleRadioInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-md-right text-left`}><Form.Label>GST Number</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="text"
                                            name="gstNumber"
                                            value={formData.gstNumber}
                                            onChange={handleInputChange}
                                        />
                                        {errors.gstNumber && <p style={{ color: "red" }}>{errors.gstNumber}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-md-right text-left`}><Form.Label>Certifications</Form.Label></div>
                                    <div className='col-md-10'>
                                        {formData.certifications.map((cert, index) => (
                                            <div key={index} className="mb-3">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Certification Name"
                                                    value={cert.name}
                                                    onChange={(e) =>
                                                        handleCertifications(index, 'name', e.target.value)
                                                    }
                                                    className="mb-2"
                                                />
                                                <Form.Control
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) =>
                                                        handleCertificateChange(index, 'image', e.target.files[0])
                                                    }
                                                />
                                                {cert.image && (
                                                    <div className={sty.image_container}>
                                                        <img src={`${process.env.REACT_APP_IMAGES_URL}/${cert.image}`} alt={`Certification ${index}`} className={sty.preview_image} />
                                                        {index === formData.certifications.length - 1 && ( // Only show for the last item
                                                            <button className={sty.cancel_btn} onClick={() => cancelCertificateImage(index)}>&times;</button>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        ))}

                                        <div className="popup-buttons">
                                            <Button className="secondary" variant="secondary" onClick={addCertificationField}>
                                                Add Certifications
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Form.Group>
                            <div className="popup-buttons">
                                <Button className="secondary" variant="secondary" onClick={() => history.push(`/mart/${authservice.getCurrentUserName()}`)}>
                                    Cancel
                                </Button>
                                <Button className="secondary" variant="primary" onClick={handleSubmit}>
                                    Submit
                                </Button>
                            </div>
                        </Form>
                        {/* </div> */}
                    </div>
                )}

                {showApprovalPopup && (
                    <div className="staff-popup-overlay text-center">
                        <div className="popup-content text-center">
                            <p>You do need permission to add your mart.<br></br>Send an email to <strong>moderation@aajubaaju.in</strong> to get permission.</p>
                            <div className="popup-buttons">
                                <Button className="secondary" variant="secondary" onClick={() => setShowApprovalPopup(false)}>
                                    Ok
                                </Button>
                            </div>
                        </div>
                    </div>
                )}

                {!formVisible && (
                    <>
                        <p>
                            <strong>
                                Note: To enable '<i>Add Product Inventory</i>' button, you have to wait for the moderation to publish your mart.
                            </strong>
                        </p>
                        <div className="table-responsive">
                            <Table striped bordered hover className="mt-4">
                                <thead>
                                    <tr>
                                        <th>S.no.</th>
                                        <th>Mart Name</th>
                                        <th>Status</th>
                                        <th>Posted Date</th>
                                        <th>Last Updated Date</th>
                                        <th>Open</th>
                                        <th>Actions</th>
                                        <th>Inventory Manage</th>
                                        <th>Order lists</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {marts.map((mart, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{mart.martName}</td>
                                            <td>{mart.status}</td>
                                            <td>{mart.postedDate}</td>
                                            <td>{mart.lastUpdatedOn}</td>
                                            <td>
                                                <label>
                                                    <div
                                                        onClick={() => toggleOpen(mart.id, !mart.open)}
                                                        style={{
                                                            width: "50px",
                                                            height: "25px",
                                                            background: mart.open ? "green" : "gray",
                                                            borderRadius: "25px",
                                                            position: "relative",
                                                            transition: "background 0.3s",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                width: "20px",
                                                                height: "20px",
                                                                background: "white",
                                                                borderRadius: "50%",
                                                                position: "absolute",
                                                                top: "50%",
                                                                left: mart.open ? "27px" : "3px",
                                                                transform: "translateY(-50%)",
                                                                transition: "left 0.3s",
                                                            }}
                                                        ></div>
                                                    </div>
                                                </label>
                                            </td>
                                            <td>
                                                <Button variant="warning" onClick={() => handleEditMart(mart.id)}>
                                                    Edit
                                                </Button>
                                            </td>
                                            <td>
                                                <Button
                                                    variant="success"
                                                    disabled={mart.status === "CONTENT_APPROVAL"}
                                                    onClick={() => history.push(`/mart/${mart.id}/product-inventory`)}
                                                    className="ml-2"
                                                >
                                                    Add Product Inventory
                                                </Button>
                                            </td>
                                            <td>
                                                <Button
                                                    variant="success"
                                                    disabled={mart.status === "CONTENT_APPROVAL"}
                                                    onClick={() => history.push(`/mart/order-list/${mart.id}`)}
                                                    className="ml-2"
                                                >
                                                    Order Lists
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </>
                )}
            </div>
            <div className={`listings row ${styles.large_rect}`}>
                {!props.isAdmin && <LargeRectangle spot={"-1"} />}
            </div>
            <div className={`${styles.billBoard} row`} style={{ backgroundColor: '#e5e5f2', paddingTop: '100px', paddingBottom: '100px' }}>
                {!props.isAdmin && <BillBoard spot={"-1"} />}
            </div>
        </div>
    );
};

export default ManageMart;
