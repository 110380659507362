import axios from 'axios';
import { notifyUser, type } from '../utility/notifyUser';

const categoryUrl = process.env.REACT_APP_BACKEND_URL + '/abmgr/category';

class categoryService {

    async addNewCategory(newCategory) {
        return axios.post(categoryUrl + `/add-new`, newCategory)
            .then(response => {
                return response.data;
            }).catch(err => {
                if (err.response)
                    notifyUser('',err.response.data, type.DANGER);
                else
                    notifyUser('', "Opps...! Somthing went wrong", type.WARNING);
            })
    }

    async addNewSubCategory(newSubCategory) {
        return axios.post(categoryUrl + `/add-new-subcategory`, newSubCategory)
            .then(response => {
                return response.data;
            }).catch(err => {
                if (err.response)
                    notifyUser('',err.response.data, type.DANGER);
                else
                    notifyUser('', "Opps...! Somthing went wrong", type.WARNING);
            })
    }
}

export default new categoryService();