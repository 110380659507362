import React, { Component } from 'react'
import { TitleHeading } from '../../UIElements/headings/TitleHeading';
import { SelectFilter } from '../SelectFilter';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

class SetLocation extends Component {

    state = {
        stateOptions: [],
        cityOptions: [],
        localityOptions: [],
        neighbourhoodOptions: [],
        selectedStates: [],
        selectedCities: [],
        selectedLocalities: [],
        selectedNeighbourhoods: [],
    }
    groupedStateOptions = [];
    groupedCityOptions = [];
    groupedLocalityOptions = [];
    groupedNeighbourhoodOptions = [];

    arrayEquals = (a, b) => {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
    }

    componentDidMount = () => {
        if (this.props.allLocations.length) {
            const stateOptions = this.props.allLocations.map(item => {
                return { value: item.stateId, label: item.state }
            })
            stateOptions.sort((a, b) => (a.label > b.label) ? 1 : -1)
            this.groupedStateOptions = this.grouping(stateOptions);
            this.setState({ stateOptions: this.groupedStateOptions, locationLoad: true })

            let selectedOtions = [];
            this.props.selectedStates.forEach(e => {
                this.groupedStateOptions.some(op => {
                    if (op.value === e) {
                        selectedOtions.push(op)
                        return true
                    }
                })
            })
            this.handleState(selectedOtions);
            this.handleCity(this.getSelectedItems(this.groupedCityOptions, this.props.selectedCities));
            this.handleLocality(this.getSelectedItems(this.groupedLocalityOptions, this.props.selectedLocalities));
            this.handleNeighbourhood(this.getSelectedItems(this.groupedNeighbourhoodOptions, this.props.selectedNeighbourhoods));
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.allLocations !== this.props.allLocations) {
            const stateOptions = this.props.allLocations.map(item => {
                return { value: item.stateId, label: item.state }
            })
            stateOptions.sort((a, b) => (a.label > b.label) ? 1 : -1)
            this.groupedStateOptions = this.grouping(stateOptions);
            this.setState({ stateOptions: this.groupedStateOptions, locationLoad: true })
        }

        if (!this.arrayEquals(prevProps.selectedStates, this.props.selectedStates)) {
            let selectedOtions = [];
            this.props.selectedStates.forEach(e => {
                this.groupedStateOptions.some(op => {
                    if (op.value === e) {
                        selectedOtions.push(op)
                        return true
                    }
                })
            })
            this.handleState(selectedOtions);
        }

        if (!this.arrayEquals(prevProps.selectedCities, this.props.selectedCities)) {
            this.handleCity(this.getSelectedItems(this.groupedCityOptions, this.props.selectedCities));
        }

        if (!this.arrayEquals(prevProps.selectedLocalities, this.props.selectedLocalities)) {
            this.handleLocality(this.getSelectedItems(this.groupedLocalityOptions, this.props.selectedLocalities));
        }

        if (!this.arrayEquals(prevProps.selectedNeighbourhoods, this.props.selectedNeighbourhoods)) {
            this.handleNeighbourhood(this.getSelectedItems(this.groupedNeighbourhoodOptions, this.props.selectedNeighbourhoods));
        }
    }

    getSelectedItems = (options, selectedItems) => {
        let selectedOtions = []
        selectedItems.forEach(e => {
            options.some(op => {
                op.options.some(subOp => {
                    if (subOp.value === e) {
                        selectedOtions.push(subOp)
                        return true
                    }
                })
            })
        })
        return selectedOtions;
    }

    grouping = (options) => {
        let grouped_list = [];
        options.forEach(function iter(r) {
            return function (o) {
                var ref = r.find(p => o.group === p.group && o.label.toLowerCase() === p.label.toLowerCase());
                if (!ref) {
                    r.push(o);
                    return;
                }
                Object
                    .keys(o)
                    .filter(k => Array.isArray(o[k]))
                    .forEach(k => o[k].forEach(iter(ref[k] = ref[k] || [])));
            };
        }(grouped_list));
        return grouped_list;
    }

    newState = (data) => {
        let obj = { value: data.id, label: data.label }
        const alreadySelected = this.state.selectedStates.findIndex(e => {
            return e.value === obj.value
        })
        if (alreadySelected > -1)
            return;

        const index = this.props.allLocations.findIndex(e => {
            return e.stateId === data.stateId
        })
        if (index === -1)
            this.props._handleNewLocation(data);

        const stateOptions = this.props.allLocations.map(item => {
            return { value: item.stateId, label: item.state }
        })
        stateOptions.sort((a, b) => (a.label > b.label) ? 1 : -1)
        this.setState({ stateOptions: this.grouping(stateOptions) })
        if (this.props.isMulti)
            this.handleState([...this.state.selectedStates, obj])
        else
            this.handleState([obj])
    }

    newCity = (data) => {
        let obj = { value: data.cityId, label: data.city, group: data.stateId, state: data.state }
        const alreadySelected = this.state.selectedCities.findIndex(e => {
            return e.value === obj.value && e.group === obj.group;
        })
        if (alreadySelected > -1)
            return;

        const index = this.props.allLocations.findIndex(e => {
            return e.cityId === data.cityId
        })
        if (index === -1)
            this.props._handleNewLocation(data);

        this.handleState(this.state.selectedStates)
        if (this.props.isMulti) {
            this.handleCity([...this.state.selectedCities, obj])
        } else
            this.handleCity([obj])
    }

    newLocality = (data) => {
        let obj = { value: data.localityId, label: data.locality, group: data.cityId, city: data.city }
        const alreadySelected = this.state.selectedLocalities.findIndex(e => {
            return e.value === obj.value && e.group === obj.group;
        })
        if (alreadySelected > -1)
            return;

        const index = this.props.allLocations.findIndex(e => {
            return e.localityId === data.localityId
        })
        if (index === -1)
            this.props._handleNewLocation(data);

        this.handleCity(this.state.selectedCities)
        if (this.props.isMulti)
            this.handleLocality([...this.state.selectedLocalities, obj])
        else
            this.handleLocality([obj])
    }

    newNeighbourhood = (data) => {
        let obj = { value: data.neighbourhoodId, label: data.neighbourhood, group: data.localityId, locality: data.locality };
        const alreadySelected = this.state.selectedNeighbourhoods.findIndex(e => {
            return e.value === obj.value && e.group === obj.group;
        })
        if (alreadySelected > -1)
            return;

        const index = this.props.allLocations.findIndex(e => {
            return e.neighbourhoodId === data.neighbourhoodId
        })
        if (index === -1)
            this.props._handleNewLocation(data);

        this.handleLocality(this.state.selectedLocalities)
        if (this.props.isMulti)
            this.handleNeighbourhood([...this.state.selectedNeighbourhoods, obj])
        else
            this.handleNeighbourhood([obj])
    }

    arr_diff = (a1, a2) => {
        var a = [], diff = [];
        for (let i = 0; i < a1.length; i++)
            a[a1[i]] = true;
        for (let i = 0; i < a2.length; i++) {
            if (a[a2[i]])
                delete a[a2[i]];
            else
                a[a2[i]] = true;
        }
        for (var k in a)
            diff.push(k);
        return diff;
    }

    handleState = (selectedList) => {
        let cityOptions = [];
        let filteredStates = this.props.isMulti ? selectedList : [...selectedList]
        selectedList.forEach(state => {
            this.props.allLocations.forEach(data => {
                if (state.value === data.stateId && data.cityId)
                    cityOptions.push({
                        label: data.state,
                        options: [
                            { value: data.cityId, label: data.city, group: data.stateId, state: data.stateId }
                        ]
                    })
            })
        })
        this.groupedCityOptions = this.grouping(cityOptions)
        this.setState({
            selectedStates: filteredStates,
            cityOptions: this.groupedCityOptions
        }, () => {
            this.handleCity(this.state.selectedCities)
        })
        this.props._handleState(selectedList)
    }

    handleCity = (selectedList) => {
        let filteredCities = []
        selectedList.forEach(e => {
            this.groupedCityOptions.some(op => {
                op.options.some(subOp => {
                    if (subOp.value === e.value) {
                        filteredCities.push(subOp)
                        return true
                    }
                })
            })
        })
        let localityOptions = [];
        filteredCities.forEach(city => {
            this.props.allLocations.forEach(data => {
                if (city.value === data.cityId && data.localityId) {
                    localityOptions.push({
                        label: data.city,
                        options: [
                            { value: data.localityId, label: data.locality, group: data.cityId, city: data.cityId }
                        ]
                    })
                }
            })
        })
        this.groupedLocalityOptions = this.grouping(localityOptions);
        this.setState({
            selectedCities: filteredCities,
            localityOptions: this.groupedLocalityOptions,
        }, () => {
            this.handleLocality(this.state.selectedLocalities)
        })
        this.props._handleCity(filteredCities)
    }

    handleLocality = (selectedList) => {
        let filteredSelectedLocalities = []
        selectedList.forEach(e => {
            this.groupedLocalityOptions.some(op => {
                op.options.some(subOp => {
                    if (subOp.value === e.value) {
                        filteredSelectedLocalities.push(subOp)
                        return true
                    }
                })
            })
        })
        let neighbourhoodOptions = [];
        filteredSelectedLocalities.forEach(locality => {
            this.props.allLocations.forEach(data => {
                if (locality.value === data.localityId && data.neighbourhoodId)
                    neighbourhoodOptions.push({
                        label: data.locality,
                        options: [
                            { value: data.neighbourhoodId, label: data.neighbourhood, group: data.localityId, locality: data.localityId }
                        ]
                    })
            })
        })
        this.groupedNeighbourhoodOptions = this.grouping(neighbourhoodOptions)
        this.setState({
            selectedLocalities: filteredSelectedLocalities,
            neighbourhoodOptions: this.groupedNeighbourhoodOptions,
        }, () => {
            this.handleNeighbourhood(this.state.selectedNeighbourhoods)
        })
        this.props._handleLocality(filteredSelectedLocalities)
    }

    handleNeighbourhood = (selectedList) => {
        let filteredSelectedNeighbourhoods = []
        selectedList.forEach(e => {
            this.groupedNeighbourhoodOptions.some(op => {
                op.options.some(subOp => {
                    if (subOp.value === e.value) {
                        filteredSelectedNeighbourhoods.push(subOp)
                        return true
                    }
                })
            })
        })
        this.setState({ selectedNeighbourhoods: filteredSelectedNeighbourhoods }, () => {
            this.props.addressOptions && this.setupAddrOptions();
        })
        this.props._handleNeighbourhood(filteredSelectedNeighbourhoods)
    }

    setupAddrOptions = () => {
        this.states = this.state.selectedStates.map(data => {
            return data.value;
        })

        let addrOptions = [];
        let cityState = [];
        let localCities = [];
        let cityCities = [];
        let localLocalities = [];
        let neighbourLocalities = [];
        if (this.state.selectedNeighbourhoods.length > 0) {
            this.state.selectedCities.forEach(city => {
                cityState.push(city.state)
                cityCities.push(city.value)
            })
            this.state.selectedLocalities.forEach(local => {
                localCities.push(local.city)
                localLocalities.push(local.value)
            })
            this.state.selectedNeighbourhoods.forEach(neighbour => {
                neighbourLocalities.push(neighbour.locality)
                addrOptions.push({
                    label: 'Neighbourhoods',
                    options: [
                        { value: neighbour.value, label: neighbour.label, group: neighbour.locality, state: neighbour.stateId, city: neighbour.cityId, locality: neighbour.localityId, type: 'Neighbourhoods' }
                    ]
                })
            })


            let lc = () => { return [...new Set(localCities)] };
            let cc = () => { return [...new Set(cityCities)] };

            let cs = () => { return [...new Set(cityState)] };
            let ss = () => { return [...new Set(this.states)] };

            let ll = () => { return [...new Set(localLocalities)] };
            let nl = () => { return [...new Set(neighbourLocalities)] };

            const diff_cities = this.arr_diff(cc(), lc())
            const diff_states = this.arr_diff(cs(), ss())
            const diff_localities = this.arr_diff(ll(), nl())

            diff_states.forEach(state => {
                var stateIndex = this.state.selectedStates.findIndex(s => {
                    return s.value === parseInt(state);
                })
                addrOptions.push({
                    label: 'States',
                    options: [
                        { value: parseInt(state), label: this.state.selectedStates[stateIndex].label, type: 'States' }
                    ]
                })
            })

            diff_cities.forEach(city => {
                var cityIndex = this.state.selectedCities.findIndex(c => {
                    return c.value === parseInt(city);
                })
                addrOptions.push({
                    label: 'Cities',
                    options: [
                        { value: parseInt(city), label: this.state.selectedCities[cityIndex].label, type: 'Cities' }
                    ]
                })
            })
            diff_localities.forEach(local => {
                var localIndex = this.state.selectedLocalities.findIndex(l => {
                    return l.value === parseInt(local);
                })
                addrOptions.push({
                    label: 'Localities',
                    options: [
                        { value: parseInt(local), label: this.state.selectedLocalities[localIndex].label, type: 'Localities' }
                    ]
                })
            })
        } else if (this.state.selectedLocalities.length > 0) {
            this.state.selectedCities.forEach(city => {
                cityState.push(city.state)
                cityCities.push(city.value)
            })
            this.state.selectedLocalities.forEach(local => {
                localCities.push(local.city)
                localLocalities.push(local.value)
                addrOptions.push({
                    label: 'Localities',
                    options: [
                        { value: local.value, label: local.label, group: local.city, state: local.stateId, city: local.cityId, type: 'Localities' }
                    ]
                })
            })

            let lc = () => { return [...new Set(localCities)] };
            let cc = () => { return [...new Set(cityCities)] };

            let cs = () => { return [...new Set(cityState)] };
            let ss = () => { return [...new Set(this.states)] };

            const diff_cities = this.arr_diff(cc(), lc())
            const diff_states = this.arr_diff(cs(), ss())

            diff_states.forEach(state => {
                var stateIndex = this.state.selectedStates.findIndex(s => {
                    return s.value === parseInt(state);
                })
                addrOptions.push({
                    label: 'States',
                    options: [
                        { value: parseInt(state), label: this.state.selectedStates[stateIndex].label, type: 'States' }
                    ]
                })
            })
            diff_cities.forEach(city => {
                var cityIndex = this.state.selectedCities.findIndex(c => {
                    return c.value === parseInt(city);
                })
                addrOptions.push({
                    label: 'Cities',
                    options: [
                        { value: parseInt(city), label: this.state.selectedCities[cityIndex].label, type: 'Cities' }
                    ]
                })
            })
        } else if (this.state.selectedCities.length > 0) {
            this.state.selectedCities.forEach(city => {
                cityState.push(city.state)
                cityCities.push(city.value)
                addrOptions.push({
                    label: 'Cities',
                    options: [
                        { value: city.value, label: city.label, group: city.state, state: city.stateId, type: 'Cities' }
                    ]
                })
            })

            let cs = () => { return [...new Set(cityState)] };
            let ss = () => { return [...new Set(this.states)] };

            const diff_states = this.arr_diff(cs(), ss())

            diff_states.forEach(state => {
                var stateIndex = this.state.selectedStates.findIndex(s => {
                    return s.value === parseInt(state);
                })
                addrOptions.push({
                    label: 'States',
                    options: [
                        { value: parseInt(state), label: this.state.selectedStates[stateIndex].label, type: 'States' }
                    ]
                })
            })
        } else {
            this.state.selectedStates.forEach(state => {
                addrOptions.push({
                    label: 'States',
                    options: [
                        { value: state.value, label: state.label, type: 'States' }
                    ]
                })
            })
        }
        this.props.setAddressOptions(this.grouping(addrOptions))
    }

    render() {
        const heading = this.props.heading ? this.props.heading : 'Set Location';
        return (
            <>
            
                <TitleHeading text={heading + ' : '} />
                {this.props.content_page === 'FormsPage' && <p className='locAdd'>If the required location is not found, please select the nearest location available. We will add the required location during the moderation process.</p>}
                <SelectFilter
                    id='Country'
                    onChange={null}
                    selectedItems={[{ value: 1, label: 'India' }]}
                    options={[{ value: 1, label: 'India' }]}
                    disabled={true}
                    {...this.props}
                />
                <SelectFilter
                    id='State'
                    onChange={this.handleState}
                    options={this.state.stateOptions}
                    selectedItems={this.state.selectedStates}
                    parentFilter='Country'
                    handleNewFilter={this.newState}
                    {...this.props}
                />
                <SelectFilter
                    id='City'
                    onChange={this.handleCity}
                    options={this.state.cityOptions}
                    selectedItems={this.state.selectedCities}
                    parentOptions={this.state.selectedStates}
                    parentFilter='State'
                    handleNewFilter={this.newCity}
                    {...this.props}
                />
                <SelectFilter
                    id='Locality'
                    onChange={this.handleLocality}
                    options={this.state.localityOptions}
                    selectedItems={this.state.selectedLocalities}
                    parentOptions={this.state.selectedCities}
                    parentFilter='City'
                    handleNewFilter={this.newLocality}
                    {...this.props}
                />
                <SelectFilter
                    id='Neighbourhood'
                    onChange={this.handleNeighbourhood}
                    options={this.state.neighbourhoodOptions}
                    selectedItems={this.state.selectedNeighbourhoods}
                    parentOptions={this.state.selectedLocalities}
                    parentFilter='Locality'
                    handleNewFilter={this.newNeighbourhood}
                    {...this.props}
                />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        content_page: state.content_page,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setContentPage: (page) => dispatch({ type: 'CONTENT_PAGE', content_page: page }),
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SetLocation))