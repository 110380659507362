import React, { useEffect, useState, useCallback, useRef } from 'react';
import { AnimatedText } from '../AnimatedText';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { notifyUser, type } from '../utility/notifyUser';
import authservice from '../Authentication/service/authservice';

const intervals = [
    { delay: 3000, duration: 30000 },  // Every 3 seconds for 30 seconds
    { delay: 6000, duration: 60000 },  // Every 6 seconds for 60 seconds
    { delay: 10000, duration: 60000 }, // Every 10 seconds for 60 seconds
    { delay: 30000, duration: 60000 }, // Every 30 seconds for 60 seconds
    { delay: 60000, duration: 20 * 60000 } // Every 1 min until timeout (20 mins)
];

export const ProcessingOrder = () => {
    const { id } = useParams();
    const [currentIntervalIndex, setCurrentIntervalIndex] = useState(0);
    const intervalIdRef = useRef(null);

    const checkPaymentStatus = useCallback(() => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/product/check-payment-status/${id}`)
            .then(response => {
                const paymentData = response.data;
                console.log(paymentData);
                if (paymentData.transactionStatus === 'SUCCESS' && paymentData.identifier === 'S' || paymentData.transactionStatus === 'PAYMENT_SUCCESS' && paymentData.identifier === 'S') {
                    window.location.href = '/'

                    notifyUser("Order Placed", "Congratulations your order has been placed", "success");
                    clearInterval(intervalIdRef.current);
                } else if (paymentData.transactionStatus === 'PAYMENT_PENDING') {
                    clearInterval(intervalIdRef.current);
                    console.error('Unexpected payment status:', paymentData.transactionStatus);
                } else {
                    this.props.history.push('/')
                    notifyUser("Payment Failed", "Your order has not placed, Please contact support team", "info");

                }
            })
            .catch(error => {
                console.error('There was an error fetching the payment status!', error);
            });
    }, [id]);

    useEffect(() => {
        window.scrollTo(0, 0);

        const initialTimeout = setTimeout(() => {
            checkPaymentStatus();
        }, 1000);

        return () => clearTimeout(initialTimeout);
    }, [id, checkPaymentStatus]);

    useEffect(() => {
        const currentInterval = intervals[currentIntervalIndex];
        intervalIdRef.current = setInterval(() => {
            checkPaymentStatus();
        }, currentInterval.delay);

        const durationTimeout = setTimeout(() => {
            clearInterval(intervalIdRef.current);
            if (currentIntervalIndex < intervals.length - 1) {
                setCurrentIntervalIndex(currentIntervalIndex + 1);
            }
        }, currentInterval.duration);

        return () => {
            clearInterval(intervalIdRef.current);
            clearTimeout(durationTimeout);
        };
    }, [currentIntervalIndex, checkPaymentStatus]);


    return (
        <div className="row">
            <div className='col-md-12 text-center' style={{ marginTop: '50px' }}>
                {/* <AnimatedText text={'processing'} /> */}
                <div>
                    <div className="load-6" style={{minHeight:'500px'}}>
                        <div>
                            <div className="l-1 letter" style={{ fontSize: '44px' }}>P</div>
                            <div className="l-2 letter" style={{ fontSize: '44px' }}>r</div>
                            <div className="l-3 letter" style={{ fontSize: '44px' }}>o</div>
                            <div className="l-4 letter" style={{ fontSize: '44px' }}>c</div>
                            <div className="l-5 letter" style={{ fontSize: '44px' }}>e</div>
                            <div className="l-6 letter" style={{ fontSize: '44px' }}>s</div>
                            <div className="l-7 letter" style={{ fontSize: '44px' }}>s</div>
                            <div className="l-8 letter" style={{ fontSize: '44px' }}>i</div>
                            <div className="l-9 letter" style={{ fontSize: '44px' }}>n</div>
                            <div className="l-10 letter" style={{ fontSize: '44px' }}>g</div>
                            <div className="l-11 letter" style={{ fontSize: '44px' }}>.</div>
                            <div className="l-12 letter" style={{ fontSize: '44px' }}>.</div>
                            <div className="l-13 letter" style={{ fontSize: '44px' }}>.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

