import React, { Component } from 'react'
import { TitleHeading } from '../../UIElements/headings/TitleHeading';
import { SelectFilter } from '../SelectFilter';

class SetCategory extends Component {

    state = {
        categoryOptions: [],
        subCategoryOptions: [],
        selectedCategories: [],
        selectedSubCategories: []
    }
    groupedCategoryOptions = [];
    groupedSubCategoryOptions = [];

    arrayEquals = (a, b) => {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
    }

    componentDidMount = () => {
        if (this.props.allCategories.length) {
            const categoryOptions = this.props.allCategories.map(item => {
                return { value: item.categoryId, label: item.category }
            })
            categoryOptions.sort((a, b) => (a.label > b.label) ? 1 : -1)
            this.groupedCategoryOptions = this.grouping(categoryOptions);
            this.setState({ categoryOptions: this.groupedCategoryOptions })

            let selectedOtions = [];
            this.props.selectedCategories.forEach(e => {
                this.groupedCategoryOptions.some(op => {
                    if (op.value === e) {
                        selectedOtions.push(op)
                        return true
                    }
                })
            })
            this.handleCategory(selectedOtions);
            this.handleSubCategory(this.getSelectedItems(this.groupedSubCategoryOptions, this.props.selectedSubCategories));
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.allCategories !== this.props.allCategories) {
            const categoryOptions = this.props.allCategories.map(item => {
                return { value: item.categoryId, label: item.category }
            })
            categoryOptions.sort((a, b) => (a.label > b.label) ? 1 : -1)
            this.groupedCategoryOptions = this.grouping(categoryOptions);
            this.setState({ categoryOptions: this.groupedCategoryOptions, locationLoad: true })
        }

        if (!this.arrayEquals(prevProps.selectedCategories, this.props.selectedCategories)) {
            let selectedOtions = [];
            this.props.selectedCategories.forEach(e => {
                this.groupedCategoryOptions.some(op => {
                    if (op.value === e) {
                        selectedOtions.push(op)
                        return true
                    }
                })
            })
            this.handleCategory(selectedOtions);
        }

        if (!this.arrayEquals(prevProps.selectedSubCategories, this.props.selectedSubCategories)) {
            this.handleSubCategory(this.getSelectedItems(this.groupedSubCategoryOptions, this.props.selectedSubCategories));
        }
    }

    getSelectedItems = (options, selectedItems) => {
        let selectedOtions = []
        selectedItems.forEach(e => {
            options.some(op => {
                op.options.some(subOp => {
                    if (subOp.value === e) {
                        selectedOtions.push(subOp)
                        return true
                    }
                })
            })
        })
        return selectedOtions;
    }

    grouping = (options) => {
        let grouped_list = [];
        options.forEach(function iter(r) {
            return function (o) {
                var ref = r.find(p => o.group === p.group && o.label.toLowerCase() === p.label.toLowerCase());
                if (!ref) {
                    r.push(o);
                    return;
                }
                Object
                    .keys(o)
                    .filter(k => Array.isArray(o[k]))
                    .forEach(k => o[k].forEach(iter(ref[k] = ref[k] || [])));
            };
        }(grouped_list));
        return grouped_list;
    }

    newCategory = (data) => {
        let obj = { value: data.categoryId, label: data.category }
        const alreadySelected = this.state.selectedCategories.findIndex(e => {
            return e.value === obj.value;
        })
        if (alreadySelected > -1)
            return;

        const index = this.props.allCategories.findIndex(e => {
            return e.categoryId === data.categoryId
        })
        if (index === -1)
            this.props._handleNewCategory(data);

        const categoryOptions = this.props.allCategories.map(item => {
            return { value: item.categoryId, label: item.category }
        })
        categoryOptions.sort((a, b) => (a.label > b.label) ? 1 : -1)
        this.setState({ categoryOptions: this.grouping(categoryOptions) })
        if (this.props.isMulti)
            this.handleCategory([...this.state.selectedCategories, obj])
        else
            this.handleCategory([obj])
    }

    newSubCategory = (data) => {
        let obj = { value: data.subCategoryId, label: data.subCategory, group: data.categoryId, category: data.category };
        const alreadySelected = this.state.selectedSubCategories.findIndex(e => {
            return e.value === obj.value && e.group === obj.group;
        })
        if (alreadySelected > -1)
            return;

        const index = this.props.allCategories.findIndex(e => {
            return e.subCategoryId === data.subCategoryId
        })
        if (index === -1)
            this.props._handleNewCategory(data);

        this.handleCategory(this.state.selectedCategories)
        if (this.props.isMulti)
            this.handleSubCategory([...this.state.selectedSubCategories, obj])
        else
            this.handleSubCategory([obj])
    }

    handleCategory = (selectedList) => {
        let subCatOptions = [];
        let filteredStates = this.props.isMulti ? selectedList : [...selectedList]
        selectedList.forEach(cat => {
            this.props.allCategories.forEach(data => {
                if (cat.value === data.categoryId && data.subCategoryId)
                    subCatOptions.push({
                        label: data.category,
                        options: [
                            { value: data.subCategoryId, label: data.subCategory, group: data.categoryId, category: data.category }
                        ]
                    })
            })
        })
        this.groupedSubCategoryOptions = this.grouping(subCatOptions)
        this.setState({
            selectedCategories: filteredStates,
            subCategoryOptions: this.groupedSubCategoryOptions
        }, () => {
            this.handleSubCategory(this.state.selectedSubCategories)
        })
        this.props._handleCategory(selectedList)
    }

    handleSubCategory = (selectedList) => {
        let filteredSelectedSubCategory = []
        selectedList.forEach(e => {
            this.groupedSubCategoryOptions.some(op => {
                op.options.some(subOp => {
                    if (subOp.value === e.value) {
                        filteredSelectedSubCategory.push(subOp)
                        return true
                    }
                })
            })
        })
        this.setState({ selectedSubCategories: filteredSelectedSubCategory })
        this.props._handleSubCategory(filteredSelectedSubCategory)
    }

    render() {
        const heading = this.props.heading ? this.props.heading : 'Set Category';
        return (
            <>
                <TitleHeading text={heading + ' : '} />
                {this.props.showCategory &&
                    <SelectFilter
                        id='Category'
                        onChange={this.handleCategory}
                        options={this.state.categoryOptions}
                        selectedItems={this.state.selectedCategories}
                        handleNewFilter={this.newCategory}
                        {...this.props}
                    />}
                <SelectFilter
                    id='SubCategory'
                    onChange={this.handleSubCategory}
                    options={this.state.subCategoryOptions}
                    selectedItems={this.state.selectedSubCategories}
                    parentOptions={this.state.selectedCategories}
                    parentFilter='Category'
                    handleNewFilter={this.newSubCategory}
                    {...this.props}
                />
            </>
        )
    }
}
export default SetCategory