import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BillBoard from '../advertisements/BillBoard';
import LargeRectangle from '../advertisements/LargeRectangle';
import { TitleHeading } from '../UIElements/headings/TitleHeading';
import listingService from '../listings/service/listingService';
import { useViewportBillBoard } from '../utility/useViewport';
import { BasicMart } from './BasicMart';

const MartCards = (props) => {

    const coords = useSelector(state => state.current_coords);
    const [listings, setListings] = useState([]);
    const viewedListings = useSelector(state => state.viewedListings);
    const isBillboard = useViewportBillBoard();

    const getListingDetails = (listing) => {
        const searchCriteria = listingService.getListingSettings(listing, coords);
        const listingPath = encodeURI(`/mart/${listing.martId}/products`);
        props.history.push(listingPath, {
            place: null,
            isViewed: viewedListings.includes(listing.id),
            searchCriteria: searchCriteria,
        });
    }

    useEffect(() => {
        // if (!coords.latitude)
        //     return;

        listingService.getRecentMarts(coords, props.listingType).then(data => {
            //.sort((a, b) => moment(b.createdDate).diff(moment(a.createdDate)))
            console.log("Fetched Listings:", data);
            setListings(data);
        })
    }, [coords])

    return (
        listings.length > 0 ?
            <div>
                <div className='pt-lg-4 pb-lg-4'><TitleHeading text={`Recently Added Mart`} /></div>
                <div className="row items_list">
                    <BasicMart listings={listings}
                        showContent={getListingDetails}
                        //showContent={(listing) => props.history.push(`/mart/${listing.hpId}/products`)}
                        viewedContentIds={viewedListings}
                        type={props.listingType} />
                </div>
                <div className='row' style={{backgroundColor:'#e5e5f2', paddingTop: '100px', paddingBottom: '100px'}}>
                    {isBillboard ? <LargeRectangle spot={props.bannerAdSpot} />
                        : <BillBoard spot={props.bannerAdSpot} />}
                </div>
            </div> : <></>
    )
}

export default MartCards;