import React, { Component } from 'react';
import axios from 'axios'
import BillBoard from '../../advertisements/BillBoard';
import authservice from '../../Authentication/service/authservice';
import DateRangePicker from 'react-daterange-picker';
import "react-daterange-picker/dist/css/react-calendar.css";
import moment from 'moment';
import { TitleHeading } from '../../UIElements/headings/TitleHeading';
import { ButtonStyle } from '../../UIElements/Buttons/ButtonStyle';
import styles from '../../listings/forms/addpost.module.css';
import './performance.css'
import LargeRectangle from '../../advertisements/LargeRectangle';

class ViewWithdrawal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            withdrawal: [],
        }
    }

    componentDidMount = () => {
        window.scroll(0, 0);
        this.currentUserId = this.props.isAdmin ? this.props.userId : authservice.getCurrentUserid()
        axios.get(process.env.REACT_APP_BACKEND_URL + '/abmgr/report/loyalty-withrawal-history/' + this.currentUserId)
            .then(response => {
                if (response.data) {
                    const withdrawal = response.data ? response.data : []
                    this.setState({ withdrawal })
                }
            }).catch(error => {
                console.log(error)
            })
        
    }


    renderwithdrawalhistory() {
        return this.state.withdrawal.map((user, index) => {
            const { date, debitedAmount, debitedMode } = user //destructuring
            return (
                <tr id={'parent-row-' + date} className="table-row extra_height" key={date}>
                    <td>{index + 1}</td>
                    <td>{date}</td>
                    <td>{debitedMode}</td>
                    <td>{debitedAmount}</td>
                </tr>
            )
        })
    }

    render() {
        return (
            <>
                <div className='desktop_viewreport'>
                    <div className='ad_container'>
                        <div>
                            <div className='ViewPerformance'>
                                <TitleHeading text={'Loyalty Withdrawal History'} />
                            </div>
                            <table id='posts' className="col-md-12 tr_marginTop">
                                <tbody>
                                    <tr className="table-row extra_height">
                                        <th className='td_width'>S.No.</th>
                                        <th className='user_width'>Date of Withdrawal</th>
                                        <th className='user_width'>Type of Withdrawal</th>
                                        <th className='joindate_width'>Amount Withdrawn</th>
                                    </tr>
                                    {this.renderwithdrawalhistory()}
                                </tbody>
                            </table>
                            <div className="ViewPerformance listing_type_tabs">
                                <div className="col-md-9"></div>
                                {!this.props.isAdmin
                                    && <div className="col-md-3 add_button animated_back_button">
                                        <ButtonStyle type="button" className="form-control btn btn-block btn_font"
                                            handleClick={() => this.props.history.goBack()}>&nbsp; &laquo; &nbsp; Go Back To Dashboard </ButtonStyle>
                                    </div>}
                            </div>
                        </div>

                    </div>
                    {!this.props.isAdmin &&
                        <div className='ad_marginTop row' style={{ backgroundColor: '#e5e5f2', paddingTop: '100px', paddingBottom: '100px' }}>
                            <BillBoard spot={"-1"} />
                        </div>}
                </div>
                <div className='mobile_viewreport'>
                    <div className='ad_container'>
                        <div>
                            <div className='ViewPerformance'>
                                <TitleHeading text={'Loyalty Withdrawal History'} />
                            </div>
                            <table id='posts' className="col-md-12 tr_marginTop">
                                <tbody>
                                    <tr className="table-row extra_height">
                                        <th className='td_width'>S.No.</th>
                                        <th className='user_width'>Date of Withdrawal</th>
                                        <th className='user_width'>Type of Withdrawal</th>
                                        <th className='joindate_width'>Amount Withdrawn</th>
                                    </tr>
                                    {this.renderwithdrawalhistory()}
                                </tbody>
                            </table>
                            <div className="ViewPerformance listing_type_tabs">
                                <div className="col-md-9"></div>
                                {!this.props.isAdmin
                                    && <div className="col-md-3 add_button animated_back_button">
                                        <ButtonStyle type="button" className="form-control btn btn-block btn_font"
                                            handleClick={() => this.props.history.goBack()}>&nbsp; &laquo; &nbsp; Back</ButtonStyle>
                                    </div>}
                            </div>
                        </div>

                    </div>
                    <div className={`listings row ${styles.large_rect}`}>
                        {!this.props.isAdmin && <LargeRectangle spot={"-1"} />}
                    </div>
                </div>
            </>
        )
    }
}

export default ViewWithdrawal;