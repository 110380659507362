import React from 'react';
import styles from '../../my_content/cover.module.css';

export const HomeTab = (props) => {
    const iconBgColor = props.active ? '#fffaf5' : null;
    const iconColor = props.active ? '#FF9933' : '#138808';
    const listingTab = {
        "business": 'Business',
        "event": 'Events',
        "job": 'Jobs',
        "news": 'Pages',
        "article": 'Videos',
        "reviews": 'Reviews',
        "photos": 'Photos',
        "mart": 'Mart',
    };
    return (
        <>
            <li style={{ background: iconBgColor }} className="d-none d-md-block nav-item col-md text-center">
                <a className="nav-link" data-toggle="tab" href={"#" + props.id} role="tab" onClick={() => props.onClick(props.id)}>
                    <span
                        className={`material-symbols-outlined`}
                        style={{ fontSize: '18px', color: iconColor }}
                    >
                        {props.title}
                    </span> &nbsp; 
                    <span className={styles.title} onClick={() => props.onClick(props.id)} style={{ fontSize: '18px', color: iconColor }}>
                            {listingTab[props.id]}
                        </span>
                </a>
            </li>

            <li style={{ background: iconBgColor }} className={`d-block d-md-none nav-item ${styles.navItem} `}>
                <a className="nav-link" data-toggle="tab" href={"#" + props.id} role="tab" style={{ transition: 'none', display: 'block', width: '100%', height: '100%' }}>
                    <span
                        className={`material-symbols-outlined`}
                        style={{ fontSize: '18px', color: iconColor }}
                        onClick={() => props.onClick(props.id)}
                    >
                        {props.title}
                    </span>
                </a>
            </li>

        </>
    );
};
